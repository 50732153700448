
import { rule_compare } from "./util";


const compare_selectors = (behavior, rules, errors) => {

  if (behavior.Selectors.length !== rules.Selectors.length) {
    errors.push({
      type: "Semantic",
      message: `${behavior.Selectors.length} selectors found, ${rules.Selectors.length} expected`,
      line: 0,
      column: 0,
    });
    return;
  }

  behavior.Selectors.forEach((selector) => {
    const rule_selector = rules.Selectors.find((rule) => {
      return rule.Type === selector.Type;
    });

    if (rule_selector === undefined) {
      errors.push({
        type: "Semantic",
        message: `Selector ${selector.Type} does not match any rules`,
        line: selector.line,
        column: selector.column,
        text: selector.Text
      });
      return;
    }

    const error = rule_compare(rule_selector, selector)
    if (error) {
      errors.push({
        type: "Semantic",
        message: `Selector ${selector.Type}: ${error}`,
        line: selector.line,
        column: selector.column,
        text: selector.Text
      });
    }
  });
};

export {
  compare_selectors
};