
const send_passed = async (id, exerciseNum) => {
  await fetch(`backend/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ passed: exerciseNum })
  }).catch(err => console.log(err));
};

const send_skipped = async (id, exerciseNum) => {
  await fetch(`backend/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ skipped: exerciseNum })
  }).catch(err => console.log(err));
};

const send_errors = async (id, exerciseNum, errors) => {
  await fetch(`backend/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ errors: { exerciseNum, errors } })
  }).catch(err => console.log(err));
};

export { send_passed, send_skipped, send_errors };
