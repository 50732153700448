const intro_text =
  `
The VIPRA project is an interdisciplinary multi-university effort to analyze new strategies for reducing the risk of spread of viral infections through air-travel. 

Our approach includes a fine-scale model that tracks individual pedestrian movement in locations with crowds, such as airplanes and airports. The pedestrian movement model is based on particle dynamics models in physics like molecular dynamics. 

The VIPRA group has developed a domain-specific language (DSL) tailored to address pedestrian dynamics issues. 
This DSL features user-friendly syntax designed for non-specialists seeking to employ pedestrian dynamics simulations. 
This website provided offers users a platform to engage with the VIPRA Behaviors DSL, allowing them to practice and provide feedback on the language's usability.

There are three exercises in increasing order of difficulty. 
Please work though these exercises and take the survey is designed to evaluate the Post-Study System Usability. 
You may return to the survey at any time while doing the exercises through the survey links provided in each page.
`;

const survey_link_text =
`https://erau.qualtrics.com/jfe/form/SV_25XcMpmQ9ycX0a2`

const error_text = [
  {
    type: "Syntax",
    message: 'If there are errors',
    line: 0,
    column: 0
  },
  {
    type: "Syntax",
    message: 'They will appear here with a line and column number of where the error occurred',
    line: 10,
    column: 5
  }
];

const code_text =
  `
This is the Behavior editor. You can edit the Behavior here.

For each exercise, 
this will be pre-populated with the initial Behavior.

You will then edit the Behavior to match the Rules to the left.
`

const rules_text =
  `
This is the Rules section.
It will start with a short explanation of what the initial Behavior does.

1. Below that will be a list of Rules that you will need to implement in the Behavior editor.
  - With some possible extra information for each rule.

Types: A list of types that you will need to use in the Behavior.

References:
  (§A). A list of relevant sections from the VIPRA Behaviors documentation will be provided.
  (§S). A link to the documentation can be found at the bottom of each exercise.

When each rule is satisfied and the 'Submit' button is pressed, A 'Next' button will appear.

Pressing the 'Next' button will take you to the next exercise. Or you can continue to edit the Behavior, checking with 'Submit'.

`

export {
  intro_text,
  error_text,
  code_text,
  rules_text
}