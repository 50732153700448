

const compare_states = (behavior, rule, errors) => {
  if (behavior.States && Array.isArray(behavior.States) && behavior.States.length > 0) {
    if (behavior.States.length !== rule.States.length) {
      errors.push({
        type: "Semantic",
        message: `Expected ${rule.States.length} states, but found ${behavior.States.length}`,
        line: 0,
        column: 0
      });
      return;
    }

    behavior.States.forEach(state => {
      if (!rule.States.includes(state)) {
        errors.push({
          type: "Semantic",
          message: `State ${state} does not match any rules`,
          line: 0,
          column: 0
        });
        return;
      }
    });
  }
};

export {
  compare_states
};