// Generated from Behavior.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';

// This class defines a complete generic visitor for a parse tree produced by BehaviorParser.

export default class BehaviorVisitor extends antlr4.tree.ParseTreeVisitor {

	// Visit a parse tree produced by BehaviorParser#program.
	visitProgram(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#statement.
	visitStatement(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#location.
	visitLocation(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#location_attribute.
	visitLocation_attribute(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#loc_name.
	visitLoc_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#loc_dimensions.
	visitLoc_dimensions(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#loc_center.
	visitLoc_center(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#loc_lengths.
	visitLoc_lengths(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#loc_rotation.
	visitLoc_rotation(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#value_numeric.
	visitValue_numeric(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#value_number.
	visitValue_number(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#value_float.
	visitValue_float(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#value_range.
	visitValue_range(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#value_random.
	visitValue_random(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#random_number.
	visitRandom_number(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#random_float.
	visitRandom_float(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#float_range.
	visitFloat_range(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#number_range.
	visitNumber_range(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#value_coord.
	visitValue_coord(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#event.
	visitEvent(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#event_attribute.
	visitEvent_attribute(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#event_name.
	visitEvent_name(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#event_start.
	visitEvent_start(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#event_end.
	visitEvent_end(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition.
	visitCondition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#connector.
	visitConnector(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#or_Connector.
	visitOr_Connector(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#and_Connector.
	visitAnd_Connector(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#sub_condition.
	visitSub_condition(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Time_Elapsed_From_Event.
	visitCondition_Time_Elapsed_From_Event(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Event_Occurred.
	visitCondition_Event_Occurred(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Event_Occurring.
	visitCondition_Event_Occurring(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Event_Starting.
	visitCondition_Event_Starting(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Event_Ending.
	visitCondition_Event_Ending(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Spatial.
	visitCondition_Spatial(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Enter_Location.
	visitCondition_Enter_Location(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Exit_Location.
	visitCondition_Exit_Location(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Inside_Location.
	visitCondition_Inside_Location(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Attribute.
	visitCondition_Attribute(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#condition_Exists.
	visitCondition_Exists(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#duration.
	visitDuration(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#attribute.
	visitAttribute(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#attr_value.
	visitAttr_value(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#end_goal.
	visitEnd_goal(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#target.
	visitTarget(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#self.
	visitSelf(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#other.
	visitOther(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#nearest_type.
	visitNearest_type(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#modifier.
	visitModifier(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#location_modifier.
	visitLocation_modifier(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#id_list.
	visitId_list(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#distance.
	visitDistance(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#direction.
	visitDirection(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#front.
	visitFront(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#behind.
	visitBehind(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#ped_Selector.
	visitPed_Selector(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_attribute.
	visitSelector_attribute(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_type.
	visitSelector_type(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_selector.
	visitSelector_selector(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_from.
	visitSelector_from(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_required.
	visitSelector_required(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector.
	visitSelector(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_Percent.
	visitSelector_Percent(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_Exactly_N_Random.
	visitSelector_Exactly_N_Random(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_Everyone.
	visitSelector_Everyone(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#selector_Location.
	visitSelector_Location(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#group.
	visitGroup(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#action.
	visitAction(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#action_attribute.
	visitAction_attribute(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#action_stimulus.
	visitAction_stimulus(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#action_response.
	visitAction_response(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#action_duration.
	visitAction_duration(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#action_target.
	visitAction_target(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#sub_action.
	visitSub_action(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#action_atom.
	visitAction_atom(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#set_atom.
	visitSet_atom(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#scale_atom.
	visitScale_atom(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#declaration.
	visitDeclaration(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#decl_Ped_State.
	visitDecl_Ped_State(ctx) {
	  return this.visitChildren(ctx);
	}


	// Visit a parse tree produced by BehaviorParser#decl_Ped.
	visitDecl_Ped(ctx) {
	  return this.visitChildren(ctx);
	}



}