export default
  `This Behavior models passengers on an airplane stopping to take luggage from the overhead compartment.

1. Change how many passengers are grabbing luggage from the overhead compartment.
  - Any amount will be accepted, except the current amount of 70%.

2. Make the duration for grabbing luggage from the overhead compartment randomized and different for each pedestrian.
  - Any random duration will be accepted


  
Documentation References:
  (§S.) Selectors

  (§V.) Values
    (§V.1.3.) Random Values

  (§A.) Actions
    (§A.3.) Action Durations
`;