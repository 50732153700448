export default
  `
Types:
  Listener
.

Selector:
  Type: Listener
  Select: Everyone
.

Event:
  Name: Announcement
  Start: 10 seconds after !Start
  End: 20 seconds after !Announcement
.


Action(Listener):
  Condition: !Announcement occurring
  Response: set velocity to {0}
.
`;
