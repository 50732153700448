import { make_attribute_value } from "../values";

const make_target = (ctx) => {
  if (ctx.self()) {
    return {
      Type: "self"
    };
  }

  if (ctx.other()) {
    // TODO: add in target modifiers
    return {
      Type: "nearest"
    };
  }

  return {
    Type: "self"
  };
};

const make_response = (ctx) => {
  ctx = ctx.sub_action();

  let response = {
    Atoms: []
  };

  ctx.action_atom().forEach((atom) => {
    if (atom.set_atom()) {
      response.Atoms.push({
        Type: 'set',
        Attribute: atom.set_atom().attribute().getText(),
        Value: make_attribute_value(atom.set_atom().attr_value())
      });
    }

    // TODO: something here throws an error when making the value
    if (atom.scale_atom()) {
      response.Atoms.push({
        Type: 'scale',
        Attribute: atom.scale_atom().attribute().getText(),
        Value: make_attribute_value(atom.scale_atom().attr_value())
      });
    }
  });

  return response;
};

export {
  make_response,
  make_target,
}