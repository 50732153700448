import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { check_behavior } from '../checker/checker';
import Builder from '../builder/builder';

import References from './references';
import BhvrEditor from './editor';
import SubmitButton from './submit_button';
import NextButton from './next_button';
import ResultsText from './results_text';
import Explanation from './explanation';

import "./styles/exercise.css";
import { send_errors, send_passed, send_skipped } from '../api/api';


function Exercise(props) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [finished, setFinished] = useState(false);
  const [code, setCode] = useState("");
  const [hasChanged, setHasChanged] = useState(false);

  const [hasPassed, setHasPassed] = useState(false);
  const [passed, setPassed] = useState(false);
  const [errors, setErrors] = useState([]);

  const [rule, setRule] = useState(props.exercise);
  const [explanation, setExplanation] = useState("");

  useEffect(() => {
    if (props.exercise === -1) {
      setFinished(true);
    } else {
      const rule_json = require(`../exercises/${props.exercise}/behavior.json`);
      const rule_code = require(`../exercises/${props.exercise}/startText.js`).default;
      const rule_explanation = require(`../exercises/${props.exercise}/explanation.js`).default;
      setCode(rule_code);
      setRule(rule_json);
      setExplanation(rule_explanation);
    }
  }, [props.exercise]);

  const handle_submit = (event) => {
    event.preventDefault();
    if (!rule) return;

    const builder = new Builder();
    let build = builder.build(code);
    if (build.errors.length > 0) {
      setErrors(build.errors);
      setPassed(false);
      if (hasChanged) send_errors(searchParams.get('userid'), props.exercise, build.errors);
      setHasChanged(false);
      return;
    }

    const behaviorCheck = check_behavior(build.behavior, rule);
    if (behaviorCheck.length > 0) {
      setErrors(behaviorCheck);
      setPassed(false);
      if (hasChanged) send_errors(searchParams.get('userid'), props.exercise, behaviorCheck);
      setHasChanged(false);
      return;
    }

    setErrors([]);
    setPassed(true);
    setHasPassed(true);
    if (hasChanged) send_passed(searchParams.get('userid'), props.exercise);
  }

  const handle_next = () => {
    if (!hasPassed) send_skipped(searchParams.get('userid'), props.exercise);
    setHasChanged(false);
    setPassed(false);
    setHasPassed(false);
    setErrors([]);
    props.next(searchParams.get('userid'))
  };

  const handle_code_change = (code) => {
    setCode(code);
    setHasChanged(true);
    setPassed(false);
    setHasPassed(false);
    setErrors([]);
  };

  return (
    finished ?
      <>
        <h1>Thank you for participating!</h1>

        <div className={"referenceList"}>
          <h2>You may close this tab</h2>
          <li>
            <a href={"https://erau.qualtrics.com/jfe/form/SV_25XcMpmQ9ycX0a2"} rel="noopener" target="_blank">
              or click return to open survey in new tab
            </a>
          </li>
        </div>
      </>
      :
      <>
        <h2 className='title'>{`Exercise: ${props.exercise}`}</h2>
        <div className={'grid-container'}>
          <Explanation Text={explanation} />
          <BhvrEditor Text={code} onValueChange={handle_code_change} />
        </div >
        <SubmitButton handleSubmit={handle_submit} />
        <ResultsText passed={passed} errors={errors} />
        <NextButton Text={"Next"} handleSubmit={handle_next} />
        <References />
      </>
  );
}

export default Exercise;