export default
  `This Behavior models everyone stopping to listen to an announcement.
This is not very realistic, so we can fix that.

1. Add a new type: "Partial_Listener", that slows down to listen to the announcement but doesn't stop.

2. Change the amount of "Listeners" and "Partial_Listener".
  - Any amount will be accepted

3. Make "Partial_Listener" slow down to listen to the announcement.
  - Any amount 0 < X < 1 will be accepted


Types: Listener, Partial_Listener

References:
  (§S). Selectors
  (§A). Actions
  (§V). Values
`;