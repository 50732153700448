
import { rule_compare } from "./util.js";

const compare_actions = (behavior, rules, errors) => {
  if (behavior.Actions.length !== rules.Actions.length) {
    errors.push({
      type: "Semantic",
      message: `${behavior.Actions.length} actions found, ${rules.Actions.length} expected`,
      line: 0,
      column: 0
    });
    return;
  }

  behavior.Actions.forEach((action) => {
    // TODO: This doesn't take into account multiple actions of the same type
    const rule_action = rules.Actions.find((rule) => {
      return rule.Type === action.Type;
    });

    if (rule_action === undefined) {
      errors.push({
        type: "Semantic",
        message: `Action Does Not Match Any Rules`,
        line: action.line,
        column: action.column,
        text: action.Text
      });
      return;
    }

    const error = rule_compare(rule_action, action);
    if (error) {
      errors.push({
        type: "Semantic",
        message: `Action For ${rule_action.Type} Does Not Match Rule`,
        line: action.line,
        column: action.column,
        text: action.Text
      });
    }
  });
};

export {
  compare_actions
};