
const percent_selector_check = (behavior, errors) => {
  behavior.Selectors.forEach(selector => {
    if (selector.Select.Type === 'Percent') {
      if (selector.Select.Value.Value < 0 || selector.Select.Value.Value > 100) {
        errors.push({
          type: "Semantic",
          message: `Percent selector value is not between 0 and 100`,
          line: selector.Select.line,
          column: selector.Select.column,
          text: selector.Select.Text
        });
      }
    }
  });
};

const check_percentage_sum = (behavior, errors) => {
  let percent_sums = {};
  behavior.Selectors.forEach(selector => {
    if (!percent_sums[selector.From]) {
      percent_sums[selector.From] = 0;
    }

    if (selector.Select.Type === 'Percent') {
      if (selector.Select.Value.Type === 'number') {
        percent_sums[selector.From] += selector.Select.Value.Value;
      }
      if (selector.Select.Value.Type === 'range' || selector.Select.Value.Type === 'random') {
        percent_sums[selector.From] += selector.Select.Value.Value.min;
      }
    }
  });

  for (let type in percent_sums) {
    if (percent_sums[type] > 100) {
      const slct = behavior.Selectors.find(selector => selector.From === type);

      errors.push({
        type: "Semantic",
        message: `Percent Selector Values for ${type} add to more than 100`,
        line: slct.line,
        column: slct.column
      });
    }
  }
};

const selector_check = (behavior, errors) => {
  percent_selector_check(behavior, errors);
  check_percentage_sum(behavior, errors);
};

export {
  selector_check
};