
import antlr4 from 'antlr4';
import BehaviorLexer from '../grammar/generated/BehaviorLexer';
import BehaviorParser from '../grammar/generated/BehaviorParser';
import BehaviorVisitor from "../grammar/generated/BehaviorVisitor";

import { get_child } from './values';
import { build_selector, build_event, build_location, build_action } from './components';

export default class Builder extends BehaviorVisitor {

  _errors = [];

  _behavior = {
    Types: [],
    Selectors: [],
    Locations: [],
    Events: [],
    States: [],
    Actions: [],
  };

  build(code) {
    var chars = new antlr4.InputStream(code.toLowerCase());
    var lexer = new BehaviorLexer(chars);
    var tokens = new antlr4.CommonTokenStream(lexer);
    var parser = new BehaviorParser(tokens);
    parser.removeErrorListeners();
    const listener = (recognizer, offendingSymbol, line, column, msg, err) => {
      this._errors.push({
        type: "Syntax",
        message: msg,
        line: line,
        column: column
      });
    };

    parser.addErrorListener({
      syntaxError: listener
    });
    parser.buildParseTrees = true;
    var tree = parser.program();

    if (this._errors.length > 0) {
      return {
        behavior: null,
        errors: this._errors
      };
    }

    this.visitProgram(tree);

    return {
      behavior: this._behavior,
      errors: this._errors
    };
  }

  visitDecl_Ped(ctx) {
    get_child(ctx, 'ID').forEach(id => {
      this._behavior.Types.push(id.getText());
    });
    return this.visitChildren(ctx);
  }

  visitPed_Selector(ctx) {
    this._behavior.Selectors.push(build_selector(ctx));
    return this.visitChildren(ctx);
  }

  visitEvent(ctx) {
    this._behavior.Events.push(build_event(ctx));
    return this.visitChildren(ctx);
  }

  visitLocation(ctx) {
    this._behavior.Locations.push(build_location(ctx));
    return this.visitChildren(ctx);
  }

  visitDecl_Ped_State(ctx) {
    get_child(ctx, 'ID').forEach(id => {
      this._behavior.States.push(id.getText());
    });
    return this.visitChildren(ctx);
  }

  visitAction(ctx) {
    this._behavior.Actions.push(build_action(ctx));
    return this.visitChildren(ctx);
  }
};