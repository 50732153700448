export default
  `This Behavior is meant to simulate a gate change in an airport.

1. Add a new Type "Passenger"

2. Add a Selector for "Passengers"
  - Any Selector will be accepted, except for everyone, or no one being selected.

3. Add a Location "New_Gate"
  - Located at X: 10, Y: 35
  - Sides of length X: 20, Y: 10
  - No rotation

4. Add an Event "Gate_Change"
  - Occurs a set time after the start of the simulation, Any time will be accepted
  - Lasts for a set time, Any time will be accepted

5. Add an Action
  - In response to the "Gate_Change" event starting
  - Change the "Passengers" goal to the "New_Gate" location


Types: Passenger
Locations: New_Gate
Events: Gate_Change

References:
  (§S). Selectors
  (§L). Locations
  (§E). Events
  (§A). Actions
  (§V). Values
`;