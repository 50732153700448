import './App.css';

import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, useParams } from "react-router-dom";

import Exercise from './components/exercise.js';
import Introduction from './introduction/introduction.js';


function App() {
  let [exercise, setExercise] = useState(1);
  const maxExercise = 3;

  const next = (userid) => {
    if (exercise < maxExercise) {
      setExercise(exercise + 1)
    } else {
      setExercise(-1);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Introduction />} />
            <Route path="/exercises" element={<Exercise exercise={exercise} next={next} />} />
          </Routes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
