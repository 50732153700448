

const range_check = (behavior, errors) => {
  for (const key in behavior) {
    if (behavior.hasOwnProperty(key)) {
      if (behavior[key]) {
        if (behavior[key].Type && (behavior[key].Type === 'range' || behavior[key].Type === 'random')) {
          if (behavior[key].Value.min > behavior[key].Value.max) {
            errors.push({
              type: "Semantic",
              message: `${behavior[key].Type} value minimum is greater than maximum`,
              line: behavior[key].line,
              column: behavior[key].column
            });
          }
        }

        if (typeof behavior[key] === 'object') {
          range_check(behavior[key], errors);
        }
      }
    }
  }
};

const find_negative = (value) => {
  if (value) {
    if (value.Value) {
      let val = parseFloat(value.Value);
      if (val < 0) {
        return true;
      }

      if (value.Value.min) {
        val = parseFloat(value.Value.min);
        if (val < 0) {
          return true;
        }
      }
  
      if (value.Value.max) {
        val = parseFloat(value.Value.max);
        if (val < 0) {
          return true;
        }
      }
    }
    
    if (typeof value === 'object') {
      for (const key in value) {
        if (find_negative(value[key])) return true;
      }
    }
  }

  return false;
};

const value_sanity_check = (behavior, errors) => {
  for (const selector in behavior.Selectors) {
    const select = behavior.Selectors[selector];
    if (find_negative(select)) {
      errors.push({
        type: "Semantic",
        message: `${select.Type} Unreasonable Negative Value`,
        line: select.line,
        column: select.column
      });
    }
  }

  for (const event in behavior.Events) {
    const evnt = behavior.Events[event]
    if (find_negative(evnt)) {
      errors.push({
        type: "Semantic",
        message: `${evnt.Name} Unreasonable Negative Value`,
        line: evnt.line,
        column: evnt.column
      });
    }
  }

  for (const action in behavior.Actions) {
    const actn = behavior.Actions[action]
    if (find_negative(actn)) {
      errors.push({
        type: "Semantic",
        message: `Action For ${actn.Type} Unreasonable Negative Value`,
        line: actn.line,
        column: actn.column
      });
    }
  }
};

export {
  range_check,
  value_sanity_check
};