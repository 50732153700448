

export default function ResultsText(props) {
  return (
    <>
      {
        props.passed ?
          <div className="results_passed">{props.Text ? props.Text : "Passed!"}</div>
          :
          <>
            {
              props.errors.length === 0 ? <></> :
                < div className="results">
                  <ul>
                    {props.errors.map((error, index) => (
                      <li key={index}>{`Line: ${error.line}:${error.column}  \'${error.message}\'`}</li>
                    ))}
                  </ul>
                </div>
            }
          </>
      }
    </>
  );
};