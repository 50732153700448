import { make_response, make_target } from "./attributes/action";
import { make_select } from "./attributes/selector";
import { make_condition } from "./conditions";
import { make_numeric, make_dimensions } from "./values";

const build_event = (ctx) => {
  let attrs = {
    Name: null,
    Start: null,
    End: null,
    line: ctx.start.line,
    column: ctx.start.column,
    Text: null
  };

  ctx.event_attribute().forEach((attr) => {
    const attrType = attr.getText().split(":")[0];
    if (attrType === "name") {
      attrs.Name = attr.getText().split(":")[1];
    }
    if (attrType === "start") {
      attrs.Start = make_condition(attr.event_start().condition());
    }
    if (attrType === "end") {
      attrs.End = make_condition(attr.event_end().condition());
    }
  });

  attrs.Text = ctx.getText();

  return attrs;
};

const build_selector = (ctx) => {
  let attrs = {
    Type: null,
    Select: null,
    From: null,
    line: ctx.start.line,
    column: ctx.start.column,
    Text: null
  };

  ctx.selector_attribute().forEach((attr) => {
    const attrType = attr.getText().split(":")[0];
    if (attrType === "type") {
      attrs.Type = attr.getText().split(":")[1];
    }
    if (attrType === "select") {
      attrs.Select = make_select(attr.selector_selector().selector());
    }
    if (attrType === "from") {
      attrs.From = attr.getText().split(":")[1];
    }
  });

  if (attrs.From === null) {
    attrs.From = "pedestrian";
  }

  attrs.Text = ctx.getText();
  return attrs;
};

const build_action = (ctx) => {
  let attrs = {
    Type: null,
    Target: null,
    Condition: null,
    Duration: null,
    Response: null,
    line: ctx.start.line,
    column: ctx.start.column,
    Text: null
  };

  attrs.Type = ctx.ID().getText();

  ctx.action_attribute().forEach((attr) => {
    const attrType = attr.getText().split(":")[0];
    if (attrType === "target") {
      attrs.Target = make_target(attr.action_target().target());
    }
    if (attrType === "condition") {
      attrs.Condition = make_condition(attr.action_stimulus().condition());
    }
    if (attrType === "response") {
      attrs.Response = make_response(attr.action_response());
    }
    if (attrType === "duration") {
      attrs.Duration = make_numeric(attr.action_duration().duration().value_numeric());
    }
  });

  attrs.Text = ctx.getText();
  return attrs;
};

const build_location = (ctx) => {
  let attrs = {
    Name: null,
    Dimensions: null,
    line: ctx.start.line,
    column: ctx.start.column,
    Text: null
  };

  ctx.location_attribute().forEach((attr) => {
    const attrType = attr.getText().split(":")[0];
    if (attrType === "name") {
      attrs.Name = attr.getText().split(":")[1];
    }
    if (attrType === "dimensions") {
      attrs.Dimensions = make_dimensions(attr.loc_dimensions());
    }
  });

  attrs.Text = ctx.getText();

  return attrs;
};

export {
  build_selector,
  build_event,
  build_action,
  build_location
};