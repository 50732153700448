
import { useNavigate, useSearchParams } from "react-router-dom";

import { useState } from "react";

import References from "../components/references";
import Explanation from "../components/explanation";

import BhvrEditor from "../components/editor";
import SubmitButton from "../components/submit_button";
import ResultsText from "../components/results_text";

import { intro_text, error_text, code_text, rules_text } from "./intro_text";

import '../components/styles/references.css';
import '../components/styles/exercise.css';
import './introduction.css';
import NextButton from "../components/next_button";

export default function Introduction(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [code, setCode] = useState(code_text);
  const [passed, setPassed] = useState(false);

  const begin = () => {
    console.log(searchParams.get('userid'))
    navigate(`/exercises/?userid=${searchParams.get('userid')}`);
  };

  return (
    <>
      <div className={"introduction"}>
        <h1>VIPRA Behaviors</h1>
        <Explanation Text={intro_text} />
        <div className={"referenceList"}>
          <h2>The survey can be accessed at: </h2>
          <li>
            <a href={"https://erau.qualtrics.com/jfe/form/SV_25XcMpmQ9ycX0a2"} rel="noopener" target="_blank">
              https://erau.qualtrics.com/jfe/form/SV_25XcMpmQ9ycX0a2
            </a>
          </li>
        </div>
        <div className={"grid-item"}>
          <>{searchParams.get('userid') ? <button className={"beginButton"} onClick={begin}>Begin</button> : <button className={"beginButtonerror"} onClick={() => { }}>Missing User ID, Unable to Begin</button>}</>
        </div>
      </div>
      <h2>Exercise Layout and Explanation</h2>
      <div className='grid-container'>
        <Explanation Text={rules_text} />
        <BhvrEditor Text={code} onValueChange={code => { setCode(code); }} />
      </div >
      <SubmitButton Text={"Submit: press this button to check your work"} handleSubmit={() => { setPassed(!passed) }} />
      <ResultsText passed={false} errors={passed ? error_text : []} />
      <NextButton Text={"Next: press this button to continue to the next exercise"} handleSubmit={() => { }} />
      <References />
    </>
  );
}