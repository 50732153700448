import Editor from 'react-simple-code-editor';

export default function Explanation(props) {
  return (
    <div className="grid-item">
      <Editor
        value={props.Text}
        onValueChange={() => { }}
        highlight={code => code}
        padding={"4%"}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          backgroundColor: "#242323",
          fontSize: 20,
          borderRadius: "10px",
        }}
      />
    </div>
  );
};