import { make_numeric } from "./values";

const make_sub_condition = (ctx) => {
  if (ctx.condition_Attribute()) {
    return {
      Type: "Attribute",
      Attribute: ctx.condition_Attribute().attribute().getText()
    };
  }
  if (ctx.condition_Enter_Location()) {
    return {
      Type: "Enter_Location",
      Location: ctx.condition_Enter_Location().LOC_NAME().getText().split('@')[1]
    };
  }
  if (ctx.condition_Event_Ending()) {
    return {
      Type: "Event_Ending",
      Event: ctx.condition_Event_Ending().EVNT().getText().split('!')[1]
    };
  }
  if (ctx.condition_Event_Occurred()) {
    return {
      Type: "Event_Occurred",
      Event: ctx.condition_Event_Occurred().EVNT().getText().split('!')[1]
    };
  }
  if (ctx.condition_Event_Occurring()) {
    return {
      Type: "Event_Occurring",
      Event: ctx.condition_Event_Occurring().EVNT().getText().split('!')[1]
    };
  }
  if (ctx.condition_Event_Starting()) {
    return {
      Type: "Event_Starting",
      Event: ctx.condition_Event_Starting().EVNT().getText().split('!')[1]
    };
  }
  if (ctx.condition_Exists()) {
    return {
      Type: "Exists"
    };
  }
  if (ctx.condition_Exit_Location()) {
    return {
      Type: "Exit_Location",
      Location: ctx.condition_Exit_Location().LOC_NAME().getText().split('@')[1]
    };
  }
  if (ctx.condition_Inside_Location()) {
    return {
      Type: "Inside_Location",
      Location: ctx.condition_Inside_Location().LOC_NAME().getText().split('@')[1]
    };
  }
  if (ctx.condition_Spatial()) {
    return {
      Type: "Spatial",
      Distance: make_numeric(ctx.condition_Spatial().value_numeric()),
    };
  }
  if (ctx.condition_Time_Elapsed_From_Event()) {
    return {
      Type: "Time_Elapsed_From_Event",
      Event: ctx.condition_Time_Elapsed_From_Event().EVNT().getText().split('!')[1],
      Time: make_numeric(ctx.condition_Time_Elapsed_From_Event().value_numeric()),
    };
  }
};

const make_condition = (ctx) => {
  let condition = {
    SubConds: []
  };

  ctx.sub_condition().forEach((sub) => {
    condition.SubConds.push(make_sub_condition(sub));
  });

  return condition;
};

export {
  make_condition
};