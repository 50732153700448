// Generated from Behavior.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';
import BehaviorVisitor from './BehaviorVisitor.js';

const serializedATN = [4,1,132,627,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,2,4,7,
4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,7,12,
2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,19,2,
20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,2,27,
7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,34,7,
34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,7,41,
2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,48,2,
49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,2,56,
7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,63,7,
63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,7,70,
2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,77,2,
78,7,78,2,79,7,79,1,0,1,0,1,0,4,0,164,8,0,11,0,12,0,165,1,1,1,1,1,1,1,1,
1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,3,1,183,8,1,1,2,1,2,1,2,5,2,
188,8,2,10,2,12,2,191,9,2,1,3,1,3,3,3,195,8,3,1,4,1,4,1,4,1,4,1,5,1,5,1,
5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,
5,1,5,1,5,1,5,1,5,1,5,3,5,227,8,5,1,6,1,6,1,6,1,6,1,7,1,7,1,7,1,7,1,8,1,
8,1,8,1,8,1,9,1,9,1,9,1,9,3,9,245,8,9,1,10,3,10,248,8,10,1,10,1,10,1,10,
3,10,253,8,10,1,10,1,10,3,10,257,8,10,1,11,3,11,260,8,11,1,11,1,11,1,11,
3,11,265,8,11,1,11,1,11,3,11,269,8,11,1,12,1,12,3,12,273,8,12,1,13,1,13,
3,13,277,8,13,1,14,1,14,1,14,1,15,1,15,1,15,1,16,1,16,1,16,1,16,1,17,1,17,
1,17,1,17,1,18,1,18,1,18,1,18,1,18,1,18,3,18,299,8,18,3,18,301,8,18,1,18,
1,18,1,19,1,19,1,19,5,19,308,8,19,10,19,12,19,311,9,19,1,20,1,20,1,20,3,
20,316,8,20,1,21,1,21,1,21,1,21,1,22,1,22,1,22,1,22,1,23,1,23,1,23,1,23,
1,24,1,24,1,24,1,24,5,24,334,8,24,10,24,12,24,337,9,24,1,25,1,25,3,25,341,
8,25,1,26,1,26,1,27,1,27,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,
28,1,28,3,28,358,8,28,1,29,1,29,1,29,1,29,1,29,1,30,1,30,1,30,1,30,1,31,
1,31,1,31,1,32,1,32,1,32,1,33,1,33,1,33,1,34,1,34,1,34,1,34,1,35,1,35,1,
35,1,36,1,36,1,36,1,37,1,37,1,37,1,38,3,38,392,8,38,1,38,1,38,1,38,3,38,
397,8,38,1,38,1,38,1,39,1,39,1,39,1,39,3,39,405,8,39,1,39,5,39,408,8,39,
10,39,12,39,411,9,39,1,39,1,39,1,39,1,39,3,39,417,8,39,1,39,1,39,1,40,1,
40,1,40,1,41,1,41,1,41,1,41,1,41,1,41,1,41,3,41,431,8,41,1,42,1,42,1,42,
1,42,3,42,437,8,42,1,43,1,43,1,43,1,44,1,44,1,44,3,44,445,8,44,1,44,5,44,
448,8,44,10,44,12,44,451,9,44,3,44,453,8,44,1,45,1,45,1,46,1,46,1,47,1,47,
1,47,1,48,1,48,1,48,3,48,465,8,48,1,49,1,49,1,49,1,50,1,50,3,50,472,8,50,
1,50,5,50,475,8,50,10,50,12,50,478,9,50,1,51,1,51,1,51,1,51,1,52,1,52,3,
52,486,8,52,1,53,1,53,1,53,1,54,1,54,1,55,1,55,1,55,5,55,496,8,55,10,55,
12,55,499,9,55,1,56,1,56,1,56,1,56,3,56,505,8,56,1,57,1,57,1,57,1,57,1,58,
1,58,1,58,1,58,1,59,1,59,1,59,1,59,1,60,1,60,1,61,1,61,1,61,1,61,3,61,525,
8,61,1,62,1,62,1,62,1,63,1,63,1,64,1,64,1,65,1,65,1,65,1,66,1,66,1,67,1,
67,1,67,1,67,1,67,1,67,5,67,545,8,67,10,67,12,67,548,9,67,1,68,1,68,1,68,
1,68,3,68,554,8,68,1,69,1,69,1,69,1,69,1,70,1,70,1,70,1,70,1,71,1,71,1,71,
1,71,1,72,1,72,1,72,1,72,1,73,1,73,1,73,5,73,575,8,73,10,73,12,73,578,9,
73,1,74,1,74,3,74,582,8,74,1,75,1,75,3,75,586,8,75,1,75,1,75,1,75,1,75,1,
76,1,76,3,76,594,8,76,1,76,1,76,1,76,1,77,1,77,3,77,601,8,77,1,78,1,78,1,
78,1,78,3,78,607,8,78,1,78,5,78,610,8,78,10,78,12,78,613,9,78,1,79,1,79,
1,79,1,79,3,79,619,8,79,1,79,5,79,622,8,79,10,79,12,79,625,9,79,1,79,0,0,
80,0,2,4,6,8,10,12,14,16,18,20,22,24,26,28,30,32,34,36,38,40,42,44,46,48,
50,52,54,56,58,60,62,64,66,68,70,72,74,76,78,80,82,84,86,88,90,92,94,96,
98,100,102,104,106,108,110,112,114,116,118,120,122,124,126,128,130,132,134,
136,138,140,142,144,146,148,150,152,154,156,158,0,5,2,0,2,2,111,111,1,0,
97,98,2,0,80,80,130,130,1,0,7,8,2,0,80,81,130,130,628,0,160,1,0,0,0,2,182,
1,0,0,0,4,184,1,0,0,0,6,194,1,0,0,0,8,196,1,0,0,0,10,200,1,0,0,0,12,228,
1,0,0,0,14,232,1,0,0,0,16,236,1,0,0,0,18,244,1,0,0,0,20,256,1,0,0,0,22,268,
1,0,0,0,24,272,1,0,0,0,26,276,1,0,0,0,28,278,1,0,0,0,30,281,1,0,0,0,32,284,
1,0,0,0,34,288,1,0,0,0,36,292,1,0,0,0,38,304,1,0,0,0,40,315,1,0,0,0,42,317,
1,0,0,0,44,321,1,0,0,0,46,325,1,0,0,0,48,329,1,0,0,0,50,340,1,0,0,0,52,342,
1,0,0,0,54,344,1,0,0,0,56,357,1,0,0,0,58,359,1,0,0,0,60,364,1,0,0,0,62,368,
1,0,0,0,64,371,1,0,0,0,66,374,1,0,0,0,68,377,1,0,0,0,70,381,1,0,0,0,72,384,
1,0,0,0,74,387,1,0,0,0,76,391,1,0,0,0,78,400,1,0,0,0,80,420,1,0,0,0,82,430,
1,0,0,0,84,436,1,0,0,0,86,438,1,0,0,0,88,452,1,0,0,0,90,454,1,0,0,0,92,456,
1,0,0,0,94,458,1,0,0,0,96,464,1,0,0,0,98,466,1,0,0,0,100,469,1,0,0,0,102,
479,1,0,0,0,104,485,1,0,0,0,106,487,1,0,0,0,108,490,1,0,0,0,110,492,1,0,
0,0,112,504,1,0,0,0,114,506,1,0,0,0,116,510,1,0,0,0,118,514,1,0,0,0,120,
518,1,0,0,0,122,524,1,0,0,0,124,526,1,0,0,0,126,529,1,0,0,0,128,531,1,0,
0,0,130,533,1,0,0,0,132,536,1,0,0,0,134,538,1,0,0,0,136,553,1,0,0,0,138,
555,1,0,0,0,140,559,1,0,0,0,142,563,1,0,0,0,144,567,1,0,0,0,146,571,1,0,
0,0,148,581,1,0,0,0,150,583,1,0,0,0,152,591,1,0,0,0,154,600,1,0,0,0,156,
602,1,0,0,0,158,614,1,0,0,0,160,161,3,158,79,0,161,163,5,1,0,0,162,164,3,
2,1,0,163,162,1,0,0,0,164,165,1,0,0,0,165,163,1,0,0,0,165,166,1,0,0,0,166,
1,1,0,0,0,167,168,3,38,19,0,168,169,5,1,0,0,169,183,1,0,0,0,170,171,3,4,
2,0,171,172,5,1,0,0,172,183,1,0,0,0,173,174,3,154,77,0,174,175,5,1,0,0,175,
183,1,0,0,0,176,177,3,110,55,0,177,178,5,1,0,0,178,183,1,0,0,0,179,180,3,
134,67,0,180,181,5,1,0,0,181,183,1,0,0,0,182,167,1,0,0,0,182,170,1,0,0,0,
182,173,1,0,0,0,182,176,1,0,0,0,182,179,1,0,0,0,183,3,1,0,0,0,184,185,5,
65,0,0,185,189,5,14,0,0,186,188,3,6,3,0,187,186,1,0,0,0,188,191,1,0,0,0,
189,187,1,0,0,0,189,190,1,0,0,0,190,5,1,0,0,0,191,189,1,0,0,0,192,195,3,
8,4,0,193,195,3,10,5,0,194,192,1,0,0,0,194,193,1,0,0,0,195,7,1,0,0,0,196,
197,5,68,0,0,197,198,5,14,0,0,198,199,5,130,0,0,199,9,1,0,0,0,200,201,5,
64,0,0,201,226,5,14,0,0,202,203,3,12,6,0,203,204,3,14,7,0,204,205,3,16,8,
0,205,227,1,0,0,0,206,207,3,12,6,0,207,208,3,16,8,0,208,209,3,14,7,0,209,
227,1,0,0,0,210,211,3,14,7,0,211,212,3,12,6,0,212,213,3,16,8,0,213,227,1,
0,0,0,214,215,3,14,7,0,215,216,3,16,8,0,216,217,3,12,6,0,217,227,1,0,0,0,
218,219,3,16,8,0,219,220,3,12,6,0,220,221,3,14,7,0,221,227,1,0,0,0,222,223,
3,16,8,0,223,224,3,14,7,0,224,225,3,12,6,0,225,227,1,0,0,0,226,202,1,0,0,
0,226,206,1,0,0,0,226,210,1,0,0,0,226,214,1,0,0,0,226,218,1,0,0,0,226,222,
1,0,0,0,227,11,1,0,0,0,228,229,5,31,0,0,229,230,5,14,0,0,230,231,3,36,18,
0,231,13,1,0,0,0,232,233,5,61,0,0,233,234,5,14,0,0,234,235,3,36,18,0,235,
15,1,0,0,0,236,237,5,91,0,0,237,238,5,14,0,0,238,239,3,18,9,0,239,17,1,0,
0,0,240,245,3,20,10,0,241,245,3,22,11,0,242,245,3,24,12,0,243,245,3,26,13,
0,244,240,1,0,0,0,244,241,1,0,0,0,244,242,1,0,0,0,244,243,1,0,0,0,245,19,
1,0,0,0,246,248,5,2,0,0,247,246,1,0,0,0,247,248,1,0,0,0,248,249,1,0,0,0,
249,257,5,131,0,0,250,252,5,3,0,0,251,253,5,2,0,0,252,251,1,0,0,0,252,253,
1,0,0,0,253,254,1,0,0,0,254,255,5,131,0,0,255,257,5,4,0,0,256,247,1,0,0,
0,256,250,1,0,0,0,257,21,1,0,0,0,258,260,5,2,0,0,259,258,1,0,0,0,259,260,
1,0,0,0,260,261,1,0,0,0,261,269,5,132,0,0,262,264,5,3,0,0,263,265,5,2,0,
0,264,263,1,0,0,0,264,265,1,0,0,0,265,266,1,0,0,0,266,267,5,132,0,0,267,
269,5,4,0,0,268,259,1,0,0,0,268,262,1,0,0,0,269,23,1,0,0,0,270,273,3,32,
16,0,271,273,3,34,17,0,272,270,1,0,0,0,272,271,1,0,0,0,273,25,1,0,0,0,274,
277,3,30,15,0,275,277,3,28,14,0,276,274,1,0,0,0,276,275,1,0,0,0,277,27,1,
0,0,0,278,279,5,86,0,0,279,280,3,34,17,0,280,29,1,0,0,0,281,282,5,86,0,0,
282,283,3,32,16,0,283,31,1,0,0,0,284,285,3,22,11,0,285,286,7,0,0,0,286,287,
3,22,11,0,287,33,1,0,0,0,288,289,3,20,10,0,289,290,7,0,0,0,290,291,3,20,
10,0,291,35,1,0,0,0,292,293,5,5,0,0,293,300,3,18,9,0,294,295,5,13,0,0,295,
298,3,18,9,0,296,297,5,13,0,0,297,299,3,18,9,0,298,296,1,0,0,0,298,299,1,
0,0,0,299,301,1,0,0,0,300,294,1,0,0,0,300,301,1,0,0,0,301,302,1,0,0,0,302,
303,5,6,0,0,303,37,1,0,0,0,304,305,5,42,0,0,305,309,5,14,0,0,306,308,3,40,
20,0,307,306,1,0,0,0,308,311,1,0,0,0,309,307,1,0,0,0,309,310,1,0,0,0,310,
39,1,0,0,0,311,309,1,0,0,0,312,316,3,42,21,0,313,316,3,44,22,0,314,316,3,
46,23,0,315,312,1,0,0,0,315,313,1,0,0,0,315,314,1,0,0,0,316,41,1,0,0,0,317,
318,5,68,0,0,318,319,5,14,0,0,319,320,5,130,0,0,320,43,1,0,0,0,321,322,5,
104,0,0,322,323,5,14,0,0,323,324,3,48,24,0,324,45,1,0,0,0,325,326,5,36,0,
0,326,327,5,14,0,0,327,328,3,48,24,0,328,47,1,0,0,0,329,335,3,56,28,0,330,
331,3,50,25,0,331,332,3,56,28,0,332,334,1,0,0,0,333,330,1,0,0,0,334,337,
1,0,0,0,335,333,1,0,0,0,335,336,1,0,0,0,336,49,1,0,0,0,337,335,1,0,0,0,338,
341,3,52,26,0,339,341,3,54,27,0,340,338,1,0,0,0,340,339,1,0,0,0,341,51,1,
0,0,0,342,343,5,79,0,0,343,53,1,0,0,0,344,345,5,21,0,0,345,55,1,0,0,0,346,
358,3,58,29,0,347,358,3,60,30,0,348,358,3,62,31,0,349,358,3,64,32,0,350,
358,3,66,33,0,351,358,3,68,34,0,352,358,3,70,35,0,353,358,3,72,36,0,354,
358,3,74,37,0,355,358,3,76,38,0,356,358,3,78,39,0,357,346,1,0,0,0,357,347,
1,0,0,0,357,348,1,0,0,0,357,349,1,0,0,0,357,350,1,0,0,0,357,351,1,0,0,0,
357,352,1,0,0,0,357,353,1,0,0,0,357,354,1,0,0,0,357,355,1,0,0,0,357,356,
1,0,0,0,358,57,1,0,0,0,359,360,3,18,9,0,360,361,5,98,0,0,361,362,5,23,0,
0,362,363,5,16,0,0,363,59,1,0,0,0,364,365,5,16,0,0,365,366,5,54,0,0,366,
367,5,75,0,0,367,61,1,0,0,0,368,369,5,16,0,0,369,370,5,76,0,0,370,63,1,0,
0,0,371,372,5,16,0,0,372,373,5,100,0,0,373,65,1,0,0,0,374,375,5,16,0,0,375,
376,5,46,0,0,376,67,1,0,0,0,377,378,5,110,0,0,378,379,5,129,0,0,379,380,
3,18,9,0,380,69,1,0,0,0,381,382,5,39,0,0,382,383,5,17,0,0,383,71,1,0,0,0,
384,385,5,63,0,0,385,386,5,17,0,0,386,73,1,0,0,0,387,388,5,55,0,0,388,389,
5,17,0,0,389,75,1,0,0,0,390,392,5,110,0,0,391,390,1,0,0,0,391,392,1,0,0,
0,392,393,1,0,0,0,393,394,3,82,41,0,394,396,5,58,0,0,395,397,5,72,0,0,396,
395,1,0,0,0,396,397,1,0,0,0,397,398,1,0,0,0,398,399,3,84,42,0,399,77,1,0,
0,0,400,401,5,41,0,0,401,402,5,14,0,0,402,409,5,130,0,0,403,405,5,13,0,0,
404,403,1,0,0,0,404,405,1,0,0,0,405,406,1,0,0,0,406,408,3,96,48,0,407,404,
1,0,0,0,408,411,1,0,0,0,409,407,1,0,0,0,409,410,1,0,0,0,410,412,1,0,0,0,
411,409,1,0,0,0,412,413,5,125,0,0,413,414,3,82,41,0,414,416,5,58,0,0,415,
417,5,72,0,0,416,415,1,0,0,0,416,417,1,0,0,0,417,418,1,0,0,0,418,419,3,84,
42,0,419,79,1,0,0,0,420,421,3,18,9,0,421,422,7,1,0,0,422,81,1,0,0,0,423,
431,5,84,0,0,424,431,5,122,0,0,425,431,5,52,0,0,426,431,3,86,43,0,427,431,
5,105,0,0,428,431,5,65,0,0,429,431,5,99,0,0,430,423,1,0,0,0,430,424,1,0,
0,0,430,425,1,0,0,0,430,426,1,0,0,0,430,427,1,0,0,0,430,428,1,0,0,0,430,
429,1,0,0,0,431,83,1,0,0,0,432,437,3,18,9,0,433,437,3,36,18,0,434,437,5,
15,0,0,435,437,5,17,0,0,436,432,1,0,0,0,436,433,1,0,0,0,436,434,1,0,0,0,
436,435,1,0,0,0,437,85,1,0,0,0,438,439,5,36,0,0,439,440,5,52,0,0,440,87,
1,0,0,0,441,453,3,90,45,0,442,449,3,92,46,0,443,445,5,13,0,0,444,443,1,0,
0,0,444,445,1,0,0,0,445,446,1,0,0,0,446,448,3,96,48,0,447,444,1,0,0,0,448,
451,1,0,0,0,449,447,1,0,0,0,449,450,1,0,0,0,450,453,1,0,0,0,451,449,1,0,
0,0,452,441,1,0,0,0,452,442,1,0,0,0,453,89,1,0,0,0,454,455,5,93,0,0,455,
91,1,0,0,0,456,457,3,94,47,0,457,93,1,0,0,0,458,459,5,69,0,0,459,460,3,100,
50,0,460,95,1,0,0,0,461,465,3,104,52,0,462,465,3,102,51,0,463,465,3,98,49,
0,464,461,1,0,0,0,464,462,1,0,0,0,464,463,1,0,0,0,465,97,1,0,0,0,466,467,
5,55,0,0,467,468,5,17,0,0,468,99,1,0,0,0,469,476,7,2,0,0,470,472,5,13,0,
0,471,470,1,0,0,0,471,472,1,0,0,0,472,473,1,0,0,0,473,475,7,2,0,0,474,471,
1,0,0,0,475,478,1,0,0,0,476,474,1,0,0,0,476,477,1,0,0,0,477,101,1,0,0,0,
478,476,1,0,0,0,479,480,5,129,0,0,480,481,3,18,9,0,481,482,7,3,0,0,482,103,
1,0,0,0,483,486,3,106,53,0,484,486,3,108,54,0,485,483,1,0,0,0,485,484,1,
0,0,0,486,105,1,0,0,0,487,488,5,55,0,0,488,489,5,49,0,0,489,107,1,0,0,0,
490,491,5,27,0,0,491,109,1,0,0,0,492,493,5,95,0,0,493,497,5,14,0,0,494,496,
3,112,56,0,495,494,1,0,0,0,496,499,1,0,0,0,497,495,1,0,0,0,497,498,1,0,0,
0,498,111,1,0,0,0,499,497,1,0,0,0,500,505,3,114,57,0,501,505,3,116,58,0,
502,505,3,118,59,0,503,505,3,120,60,0,504,500,1,0,0,0,504,501,1,0,0,0,504,
502,1,0,0,0,504,503,1,0,0,0,505,113,1,0,0,0,506,507,5,119,0,0,507,508,5,
14,0,0,508,509,3,100,50,0,509,115,1,0,0,0,510,511,5,94,0,0,511,512,5,14,
0,0,512,513,3,122,61,0,513,117,1,0,0,0,514,515,5,48,0,0,515,516,5,14,0,0,
516,517,3,132,66,0,517,119,1,0,0,0,518,519,5,87,0,0,519,121,1,0,0,0,520,
525,3,124,62,0,521,525,3,126,63,0,522,525,3,128,64,0,523,525,3,130,65,0,
524,520,1,0,0,0,524,521,1,0,0,0,524,522,1,0,0,0,524,523,1,0,0,0,525,123,
1,0,0,0,526,527,3,18,9,0,527,528,5,9,0,0,528,125,1,0,0,0,529,530,3,18,9,
0,530,127,1,0,0,0,531,532,5,45,0,0,532,129,1,0,0,0,533,534,5,55,0,0,534,
535,5,17,0,0,535,131,1,0,0,0,536,537,7,4,0,0,537,133,1,0,0,0,538,539,5,26,
0,0,539,540,5,3,0,0,540,541,5,130,0,0,541,542,5,4,0,0,542,546,5,14,0,0,543,
545,3,136,68,0,544,543,1,0,0,0,545,548,1,0,0,0,546,544,1,0,0,0,546,547,1,
0,0,0,547,135,1,0,0,0,548,546,1,0,0,0,549,554,3,140,70,0,550,554,3,138,69,
0,551,554,3,142,71,0,552,554,3,144,72,0,553,549,1,0,0,0,553,550,1,0,0,0,
553,551,1,0,0,0,553,552,1,0,0,0,554,137,1,0,0,0,555,556,5,32,0,0,556,557,
5,14,0,0,557,558,3,48,24,0,558,139,1,0,0,0,559,560,5,90,0,0,560,561,5,14,
0,0,561,562,3,146,73,0,562,141,1,0,0,0,563,564,5,35,0,0,564,565,5,14,0,0,
565,566,3,80,40,0,566,143,1,0,0,0,567,568,5,110,0,0,568,569,5,14,0,0,569,
570,3,88,44,0,570,145,1,0,0,0,571,576,3,148,74,0,572,573,5,13,0,0,573,575,
3,148,74,0,574,572,1,0,0,0,575,578,1,0,0,0,576,574,1,0,0,0,576,577,1,0,0,
0,577,147,1,0,0,0,578,576,1,0,0,0,579,582,3,150,75,0,580,582,3,152,76,0,
581,579,1,0,0,0,581,580,1,0,0,0,582,149,1,0,0,0,583,585,5,96,0,0,584,586,
5,110,0,0,585,584,1,0,0,0,585,586,1,0,0,0,586,587,1,0,0,0,587,588,3,82,41,
0,588,589,5,111,0,0,589,590,3,84,42,0,590,151,1,0,0,0,591,593,5,92,0,0,592,
594,5,110,0,0,593,592,1,0,0,0,593,594,1,0,0,0,594,595,1,0,0,0,595,596,3,
82,41,0,596,597,3,84,42,0,597,153,1,0,0,0,598,601,3,156,78,0,599,601,3,158,
79,0,600,598,1,0,0,0,600,599,1,0,0,0,601,155,1,0,0,0,602,603,5,103,0,0,603,
604,5,14,0,0,604,611,5,130,0,0,605,607,5,13,0,0,606,605,1,0,0,0,606,607,
1,0,0,0,607,608,1,0,0,0,608,610,5,130,0,0,609,606,1,0,0,0,610,613,1,0,0,
0,611,609,1,0,0,0,611,612,1,0,0,0,612,157,1,0,0,0,613,611,1,0,0,0,614,615,
5,118,0,0,615,616,5,14,0,0,616,623,5,130,0,0,617,619,5,13,0,0,618,617,1,
0,0,0,618,619,1,0,0,0,619,620,1,0,0,0,620,622,5,130,0,0,621,618,1,0,0,0,
622,625,1,0,0,0,623,621,1,0,0,0,623,624,1,0,0,0,624,159,1,0,0,0,625,623,
1,0,0,0,49,165,182,189,194,226,244,247,252,256,259,264,268,272,276,298,300,
309,315,335,340,357,391,396,404,409,416,430,436,444,449,452,464,471,476,
485,497,504,524,546,553,576,581,585,593,600,606,611,618,623];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

const sharedContextCache = new antlr4.atn.PredictionContextCache();

export default class BehaviorParser extends antlr4.Parser {

    static grammarFileName = "Behavior.g4";
    static literalNames = [ null, "'.'", "'-'", "'('", "')'", "'{'", "'}'", 
                            "'M'", "'m'", "'%'", null, null, null, "','", 
                            "':'" ];
    static symbolicNames = [ null, null, null, null, null, null, null, null, 
                             null, null, "COMMENT", "LINE_COMMENT", "WS", 
                             "COMMA", "COLON", "STATE_VAL", "EVNT", "LOC_NAME", 
                             "AN", "AT", "ADD", "AND", "ARE", "AFTER", "ALWAYS", 
                             "AROUND", "ACTION", "BEHIND", "BOTTOM", "CONSIDER", 
                             "CIRCLE", "CENTER", "CONDITION", "DECIMAL", 
                             "DIRECTION", "DURATION", "END", "ENDS", "ENVIRONMENT", 
                             "ENTER", "EXIT", "EXISTS", "EVENT", "EXACTLY", 
                             "EVERY", "EVERYONE", "ENDING", "FOR", "FROM", 
                             "FRONT", "FASTER", "GIVEN", "GOAL", "GROUP", 
                             "HAS", "IN", "INTEGER", "INITIAL", "IS", "IF", 
                             "LENGTH", "LENGTHS", "LEFT", "LEAVE", "DIMENSIONS", 
                             "LOCATION", "MAX", "METERS", "NAME", "NEAREST", 
                             "NORMAL", "NONE", "NOT", "OBSTACLE", "OCCUR", 
                             "OCCURRED", "OCCURRING", "OF", "ON", "OR", 
                             "PEDESTRIAN", "PEDESTRIANS", "POINTS", "POSSIBLE", 
                             "POSITION", "RADIUS", "RANDOM", "REQUIRED", 
                             "RECTANGLE", "RIGHT", "RESPONSE", "ROTATION", 
                             "SCALE", "SELF", "SELECT", "SELECTOR", "SET", 
                             "SECOND", "SECONDS", "STATUS", "STARTING", 
                             "SLOWER", "SHAPE", "STATES", "START", "STATE", 
                             "STARTS", "SPEED", "SUBTRACT", "STIMULUS", 
                             "TARGET", "TO", "TOP", "THE", "THAT", "THEN", 
                             "THEIR", "THERES", "TYPES", "TYPE", "UNCONDITIONALLY", 
                             "VALUE", "VELOCITY", "WHEN", "WHILE", "WHOSE", 
                             "WIDTH", "WILL", "WITH", "WITHIN", "ID", "NUMBER", 
                             "FLOAT" ];
    static ruleNames = [ "program", "statement", "location", "location_attribute", 
                         "loc_name", "loc_dimensions", "loc_center", "loc_lengths", 
                         "loc_rotation", "value_numeric", "value_number", 
                         "value_float", "value_range", "value_random", "random_number", 
                         "random_float", "float_range", "number_range", 
                         "value_coord", "event", "event_attribute", "event_name", 
                         "event_start", "event_end", "condition", "connector", 
                         "or_Connector", "and_Connector", "sub_condition", 
                         "condition_Time_Elapsed_From_Event", "condition_Event_Occurred", 
                         "condition_Event_Occurring", "condition_Event_Starting", 
                         "condition_Event_Ending", "condition_Spatial", 
                         "condition_Enter_Location", "condition_Exit_Location", 
                         "condition_Inside_Location", "condition_Attribute", 
                         "condition_Exists", "duration", "attribute", "attr_value", 
                         "end_goal", "target", "self", "other", "nearest_type", 
                         "modifier", "location_modifier", "id_list", "distance", 
                         "direction", "front", "behind", "ped_Selector", 
                         "selector_attribute", "selector_type", "selector_selector", 
                         "selector_from", "selector_required", "selector", 
                         "selector_Percent", "selector_Exactly_N_Random", 
                         "selector_Everyone", "selector_Location", "group", 
                         "action", "action_attribute", "action_stimulus", 
                         "action_response", "action_duration", "action_target", 
                         "sub_action", "action_atom", "set_atom", "scale_atom", 
                         "declaration", "decl_Ped_State", "decl_Ped" ];

    constructor(input) {
        super(input);
        this._interp = new antlr4.atn.ParserATNSimulator(this, atn, decisionsToDFA, sharedContextCache);
        this.ruleNames = BehaviorParser.ruleNames;
        this.literalNames = BehaviorParser.literalNames;
        this.symbolicNames = BehaviorParser.symbolicNames;
    }



	program() {
	    let localctx = new ProgramContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 0, BehaviorParser.RULE_program);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 160;
	        this.decl_Ped();
	        this.state = 161;
	        this.match(BehaviorParser.T__0);
	        this.state = 163; 
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        do {
	            this.state = 162;
	            this.statement();
	            this.state = 165; 
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        } while(_la===26 || _la===42 || _la===65 || ((((_la - 95)) & ~0x1f) === 0 && ((1 << (_la - 95)) & 8388865) !== 0));
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	statement() {
	    let localctx = new StatementContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 2, BehaviorParser.RULE_statement);
	    try {
	        this.state = 182;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 42:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 167;
	            this.event();
	            this.state = 168;
	            this.match(BehaviorParser.T__0);
	            break;
	        case 65:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 170;
	            this.location();
	            this.state = 171;
	            this.match(BehaviorParser.T__0);
	            break;
	        case 103:
	        case 118:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 173;
	            this.declaration();
	            this.state = 174;
	            this.match(BehaviorParser.T__0);
	            break;
	        case 95:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 176;
	            this.ped_Selector();
	            this.state = 177;
	            this.match(BehaviorParser.T__0);
	            break;
	        case 26:
	            this.enterOuterAlt(localctx, 5);
	            this.state = 179;
	            this.action();
	            this.state = 180;
	            this.match(BehaviorParser.T__0);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	location() {
	    let localctx = new LocationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 4, BehaviorParser.RULE_location);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 184;
	        this.match(BehaviorParser.LOCATION);
	        this.state = 185;
	        this.match(BehaviorParser.COLON);
	        this.state = 189;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===64 || _la===68) {
	            this.state = 186;
	            this.location_attribute();
	            this.state = 191;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	location_attribute() {
	    let localctx = new Location_attributeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 6, BehaviorParser.RULE_location_attribute);
	    try {
	        this.state = 194;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 68:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 192;
	            this.loc_name();
	            break;
	        case 64:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 193;
	            this.loc_dimensions();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	loc_name() {
	    let localctx = new Loc_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 8, BehaviorParser.RULE_loc_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 196;
	        this.match(BehaviorParser.NAME);
	        this.state = 197;
	        this.match(BehaviorParser.COLON);
	        this.state = 198;
	        this.match(BehaviorParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	loc_dimensions() {
	    let localctx = new Loc_dimensionsContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 10, BehaviorParser.RULE_loc_dimensions);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 200;
	        this.match(BehaviorParser.DIMENSIONS);
	        this.state = 201;
	        this.match(BehaviorParser.COLON);
	        this.state = 226;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,4,this._ctx);
	        switch(la_) {
	        case 1:
	            this.state = 202;
	            this.loc_center();
	            this.state = 203;
	            this.loc_lengths();
	            this.state = 204;
	            this.loc_rotation();
	            break;

	        case 2:
	            this.state = 206;
	            this.loc_center();
	            this.state = 207;
	            this.loc_rotation();
	            this.state = 208;
	            this.loc_lengths();
	            break;

	        case 3:
	            this.state = 210;
	            this.loc_lengths();
	            this.state = 211;
	            this.loc_center();
	            this.state = 212;
	            this.loc_rotation();
	            break;

	        case 4:
	            this.state = 214;
	            this.loc_lengths();
	            this.state = 215;
	            this.loc_rotation();
	            this.state = 216;
	            this.loc_center();
	            break;

	        case 5:
	            this.state = 218;
	            this.loc_rotation();
	            this.state = 219;
	            this.loc_center();
	            this.state = 220;
	            this.loc_lengths();
	            break;

	        case 6:
	            this.state = 222;
	            this.loc_rotation();
	            this.state = 223;
	            this.loc_lengths();
	            this.state = 224;
	            this.loc_center();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	loc_center() {
	    let localctx = new Loc_centerContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 12, BehaviorParser.RULE_loc_center);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 228;
	        this.match(BehaviorParser.CENTER);
	        this.state = 229;
	        this.match(BehaviorParser.COLON);
	        this.state = 230;
	        this.value_coord();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	loc_lengths() {
	    let localctx = new Loc_lengthsContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 14, BehaviorParser.RULE_loc_lengths);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 232;
	        this.match(BehaviorParser.LENGTHS);
	        this.state = 233;
	        this.match(BehaviorParser.COLON);
	        this.state = 234;
	        this.value_coord();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	loc_rotation() {
	    let localctx = new Loc_rotationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 16, BehaviorParser.RULE_loc_rotation);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 236;
	        this.match(BehaviorParser.ROTATION);
	        this.state = 237;
	        this.match(BehaviorParser.COLON);
	        this.state = 238;
	        this.value_numeric();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	value_numeric() {
	    let localctx = new Value_numericContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 18, BehaviorParser.RULE_value_numeric);
	    try {
	        this.state = 244;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,5,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 240;
	            this.value_number();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 241;
	            this.value_float();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 242;
	            this.value_range();
	            break;

	        case 4:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 243;
	            this.value_random();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	value_number() {
	    let localctx = new Value_numberContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 20, BehaviorParser.RULE_value_number);
	    var _la = 0;
	    try {
	        this.state = 256;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 2:
	        case 131:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 247;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===2) {
	                this.state = 246;
	                this.match(BehaviorParser.T__1);
	            }

	            this.state = 249;
	            this.match(BehaviorParser.NUMBER);
	            break;
	        case 3:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 250;
	            this.match(BehaviorParser.T__2);
	            this.state = 252;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===2) {
	                this.state = 251;
	                this.match(BehaviorParser.T__1);
	            }

	            this.state = 254;
	            this.match(BehaviorParser.NUMBER);
	            this.state = 255;
	            this.match(BehaviorParser.T__3);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	value_float() {
	    let localctx = new Value_floatContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 22, BehaviorParser.RULE_value_float);
	    var _la = 0;
	    try {
	        this.state = 268;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 2:
	        case 132:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 259;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===2) {
	                this.state = 258;
	                this.match(BehaviorParser.T__1);
	            }

	            this.state = 261;
	            this.match(BehaviorParser.FLOAT);
	            break;
	        case 3:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 262;
	            this.match(BehaviorParser.T__2);
	            this.state = 264;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===2) {
	                this.state = 263;
	                this.match(BehaviorParser.T__1);
	            }

	            this.state = 266;
	            this.match(BehaviorParser.FLOAT);
	            this.state = 267;
	            this.match(BehaviorParser.T__3);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	value_range() {
	    let localctx = new Value_rangeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 24, BehaviorParser.RULE_value_range);
	    try {
	        this.state = 272;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,12,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 270;
	            this.float_range();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 271;
	            this.number_range();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	value_random() {
	    let localctx = new Value_randomContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 26, BehaviorParser.RULE_value_random);
	    try {
	        this.state = 276;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,13,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 274;
	            this.random_float();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 275;
	            this.random_number();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	random_number() {
	    let localctx = new Random_numberContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 28, BehaviorParser.RULE_random_number);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 278;
	        this.match(BehaviorParser.RANDOM);
	        this.state = 279;
	        this.number_range();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	random_float() {
	    let localctx = new Random_floatContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 30, BehaviorParser.RULE_random_float);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 281;
	        this.match(BehaviorParser.RANDOM);
	        this.state = 282;
	        this.float_range();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	float_range() {
	    let localctx = new Float_rangeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 32, BehaviorParser.RULE_float_range);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 284;
	        this.value_float();
	        this.state = 285;
	        _la = this._input.LA(1);
	        if(!(_la===2 || _la===111)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	        this.state = 286;
	        this.value_float();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	number_range() {
	    let localctx = new Number_rangeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 34, BehaviorParser.RULE_number_range);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 288;
	        this.value_number();
	        this.state = 289;
	        _la = this._input.LA(1);
	        if(!(_la===2 || _la===111)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	        this.state = 290;
	        this.value_number();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	value_coord() {
	    let localctx = new Value_coordContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 36, BehaviorParser.RULE_value_coord);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 292;
	        this.match(BehaviorParser.T__4);
	        this.state = 293;
	        this.value_numeric();
	        this.state = 300;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===13) {
	            this.state = 294;
	            this.match(BehaviorParser.COMMA);
	            this.state = 295;
	            this.value_numeric();
	            this.state = 298;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===13) {
	                this.state = 296;
	                this.match(BehaviorParser.COMMA);
	                this.state = 297;
	                this.value_numeric();
	            }

	        }

	        this.state = 302;
	        this.match(BehaviorParser.T__5);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	event() {
	    let localctx = new EventContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 38, BehaviorParser.RULE_event);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 304;
	        this.match(BehaviorParser.EVENT);
	        this.state = 305;
	        this.match(BehaviorParser.COLON);
	        this.state = 309;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===36 || _la===68 || _la===104) {
	            this.state = 306;
	            this.event_attribute();
	            this.state = 311;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	event_attribute() {
	    let localctx = new Event_attributeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 40, BehaviorParser.RULE_event_attribute);
	    try {
	        this.state = 315;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 68:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 312;
	            this.event_name();
	            break;
	        case 104:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 313;
	            this.event_start();
	            break;
	        case 36:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 314;
	            this.event_end();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	event_name() {
	    let localctx = new Event_nameContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 42, BehaviorParser.RULE_event_name);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 317;
	        this.match(BehaviorParser.NAME);
	        this.state = 318;
	        this.match(BehaviorParser.COLON);
	        this.state = 319;
	        this.match(BehaviorParser.ID);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	event_start() {
	    let localctx = new Event_startContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 44, BehaviorParser.RULE_event_start);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 321;
	        this.match(BehaviorParser.START);
	        this.state = 322;
	        this.match(BehaviorParser.COLON);
	        this.state = 323;
	        this.condition();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	event_end() {
	    let localctx = new Event_endContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 46, BehaviorParser.RULE_event_end);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 325;
	        this.match(BehaviorParser.END);
	        this.state = 326;
	        this.match(BehaviorParser.COLON);
	        this.state = 327;
	        this.condition();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition() {
	    let localctx = new ConditionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 48, BehaviorParser.RULE_condition);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 329;
	        this.sub_condition();
	        this.state = 335;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===21 || _la===79) {
	            this.state = 330;
	            this.connector();
	            this.state = 331;
	            this.sub_condition();
	            this.state = 337;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	connector() {
	    let localctx = new ConnectorContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 50, BehaviorParser.RULE_connector);
	    try {
	        this.state = 340;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 79:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 338;
	            this.or_Connector();
	            break;
	        case 21:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 339;
	            this.and_Connector();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	or_Connector() {
	    let localctx = new Or_ConnectorContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 52, BehaviorParser.RULE_or_Connector);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 342;
	        this.match(BehaviorParser.OR);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	and_Connector() {
	    let localctx = new And_ConnectorContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 54, BehaviorParser.RULE_and_Connector);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 344;
	        this.match(BehaviorParser.AND);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	sub_condition() {
	    let localctx = new Sub_conditionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 56, BehaviorParser.RULE_sub_condition);
	    try {
	        this.state = 357;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,20,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 346;
	            this.condition_Time_Elapsed_From_Event();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 347;
	            this.condition_Event_Occurred();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 348;
	            this.condition_Event_Occurring();
	            break;

	        case 4:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 349;
	            this.condition_Event_Starting();
	            break;

	        case 5:
	            this.enterOuterAlt(localctx, 5);
	            this.state = 350;
	            this.condition_Event_Ending();
	            break;

	        case 6:
	            this.enterOuterAlt(localctx, 6);
	            this.state = 351;
	            this.condition_Spatial();
	            break;

	        case 7:
	            this.enterOuterAlt(localctx, 7);
	            this.state = 352;
	            this.condition_Enter_Location();
	            break;

	        case 8:
	            this.enterOuterAlt(localctx, 8);
	            this.state = 353;
	            this.condition_Exit_Location();
	            break;

	        case 9:
	            this.enterOuterAlt(localctx, 9);
	            this.state = 354;
	            this.condition_Inside_Location();
	            break;

	        case 10:
	            this.enterOuterAlt(localctx, 10);
	            this.state = 355;
	            this.condition_Attribute();
	            break;

	        case 11:
	            this.enterOuterAlt(localctx, 11);
	            this.state = 356;
	            this.condition_Exists();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Time_Elapsed_From_Event() {
	    let localctx = new Condition_Time_Elapsed_From_EventContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 58, BehaviorParser.RULE_condition_Time_Elapsed_From_Event);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 359;
	        this.value_numeric();
	        this.state = 360;
	        this.match(BehaviorParser.SECONDS);
	        this.state = 361;
	        this.match(BehaviorParser.AFTER);
	        this.state = 362;
	        this.match(BehaviorParser.EVNT);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Event_Occurred() {
	    let localctx = new Condition_Event_OccurredContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 60, BehaviorParser.RULE_condition_Event_Occurred);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 364;
	        this.match(BehaviorParser.EVNT);
	        this.state = 365;
	        this.match(BehaviorParser.HAS);
	        this.state = 366;
	        this.match(BehaviorParser.OCCURRED);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Event_Occurring() {
	    let localctx = new Condition_Event_OccurringContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 62, BehaviorParser.RULE_condition_Event_Occurring);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 368;
	        this.match(BehaviorParser.EVNT);
	        this.state = 369;
	        this.match(BehaviorParser.OCCURRING);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Event_Starting() {
	    let localctx = new Condition_Event_StartingContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 64, BehaviorParser.RULE_condition_Event_Starting);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 371;
	        this.match(BehaviorParser.EVNT);
	        this.state = 372;
	        this.match(BehaviorParser.STARTING);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Event_Ending() {
	    let localctx = new Condition_Event_EndingContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 66, BehaviorParser.RULE_condition_Event_Ending);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 374;
	        this.match(BehaviorParser.EVNT);
	        this.state = 375;
	        this.match(BehaviorParser.ENDING);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Spatial() {
	    let localctx = new Condition_SpatialContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 68, BehaviorParser.RULE_condition_Spatial);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 377;
	        this.match(BehaviorParser.TARGET);
	        this.state = 378;
	        this.match(BehaviorParser.WITHIN);
	        this.state = 379;
	        this.value_numeric();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Enter_Location() {
	    let localctx = new Condition_Enter_LocationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 70, BehaviorParser.RULE_condition_Enter_Location);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 381;
	        this.match(BehaviorParser.ENTER);
	        this.state = 382;
	        this.match(BehaviorParser.LOC_NAME);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Exit_Location() {
	    let localctx = new Condition_Exit_LocationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 72, BehaviorParser.RULE_condition_Exit_Location);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 384;
	        this.match(BehaviorParser.LEAVE);
	        this.state = 385;
	        this.match(BehaviorParser.LOC_NAME);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Inside_Location() {
	    let localctx = new Condition_Inside_LocationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 74, BehaviorParser.RULE_condition_Inside_Location);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 387;
	        this.match(BehaviorParser.IN);
	        this.state = 388;
	        this.match(BehaviorParser.LOC_NAME);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Attribute() {
	    let localctx = new Condition_AttributeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 76, BehaviorParser.RULE_condition_Attribute);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 391;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===110) {
	            this.state = 390;
	            this.match(BehaviorParser.TARGET);
	        }

	        this.state = 393;
	        this.attribute();
	        this.state = 394;
	        this.match(BehaviorParser.IS);

	        this.state = 396;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===72) {
	            this.state = 395;
	            this.match(BehaviorParser.NOT);
	        }

	        this.state = 398;
	        this.attr_value();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	condition_Exists() {
	    let localctx = new Condition_ExistsContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 78, BehaviorParser.RULE_condition_Exists);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 400;
	        this.match(BehaviorParser.EXISTS);
	        this.state = 401;
	        this.match(BehaviorParser.COLON);
	        this.state = 402;
	        this.match(BehaviorParser.ID);
	        this.state = 409;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===13 || _la===27 || _la===55 || _la===129) {
	            this.state = 404;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===13) {
	                this.state = 403;
	                this.match(BehaviorParser.COMMA);
	            }

	            this.state = 406;
	            this.modifier();
	            this.state = 411;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	        this.state = 412;
	        this.match(BehaviorParser.WHOSE);
	        this.state = 413;
	        this.attribute();
	        this.state = 414;
	        this.match(BehaviorParser.IS);

	        this.state = 416;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===72) {
	            this.state = 415;
	            this.match(BehaviorParser.NOT);
	        }

	        this.state = 418;
	        this.attr_value();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	duration() {
	    let localctx = new DurationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 80, BehaviorParser.RULE_duration);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 420;
	        this.value_numeric();
	        this.state = 421;
	        _la = this._input.LA(1);
	        if(!(_la===97 || _la===98)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	attribute() {
	    let localctx = new AttributeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 82, BehaviorParser.RULE_attribute);
	    try {
	        this.state = 430;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 84:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 423;
	            this.match(BehaviorParser.POSITION);
	            break;
	        case 122:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 424;
	            this.match(BehaviorParser.VELOCITY);
	            break;
	        case 52:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 425;
	            this.match(BehaviorParser.GOAL);
	            break;
	        case 36:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 426;
	            this.end_goal();
	            break;
	        case 105:
	            this.enterOuterAlt(localctx, 5);
	            this.state = 427;
	            this.match(BehaviorParser.STATE);
	            break;
	        case 65:
	            this.enterOuterAlt(localctx, 6);
	            this.state = 428;
	            this.match(BehaviorParser.LOCATION);
	            break;
	        case 99:
	            this.enterOuterAlt(localctx, 7);
	            this.state = 429;
	            this.match(BehaviorParser.STATUS);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	attr_value() {
	    let localctx = new Attr_valueContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 84, BehaviorParser.RULE_attr_value);
	    try {
	        this.state = 436;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 2:
	        case 3:
	        case 86:
	        case 131:
	        case 132:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 432;
	            this.value_numeric();
	            break;
	        case 5:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 433;
	            this.value_coord();
	            break;
	        case 15:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 434;
	            this.match(BehaviorParser.STATE_VAL);
	            break;
	        case 17:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 435;
	            this.match(BehaviorParser.LOC_NAME);
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	end_goal() {
	    let localctx = new End_goalContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 86, BehaviorParser.RULE_end_goal);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 438;
	        this.match(BehaviorParser.END);
	        this.state = 439;
	        this.match(BehaviorParser.GOAL);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	target() {
	    let localctx = new TargetContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 88, BehaviorParser.RULE_target);
	    var _la = 0;
	    try {
	        this.state = 452;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 93:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 441;
	            this.self();
	            break;
	        case 69:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 442;
	            this.other();
	            this.state = 449;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            while(_la===13 || _la===27 || _la===55 || _la===129) {
	                this.state = 444;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                if(_la===13) {
	                    this.state = 443;
	                    this.match(BehaviorParser.COMMA);
	                }

	                this.state = 446;
	                this.modifier();
	                this.state = 451;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	            }
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	self() {
	    let localctx = new SelfContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 90, BehaviorParser.RULE_self);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 454;
	        this.match(BehaviorParser.SELF);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	other() {
	    let localctx = new OtherContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 92, BehaviorParser.RULE_other);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 456;
	        this.nearest_type();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	nearest_type() {
	    let localctx = new Nearest_typeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 94, BehaviorParser.RULE_nearest_type);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 458;
	        this.match(BehaviorParser.NEAREST);
	        this.state = 459;
	        this.id_list();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	modifier() {
	    let localctx = new ModifierContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 96, BehaviorParser.RULE_modifier);
	    try {
	        this.state = 464;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,31,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 461;
	            this.direction();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 462;
	            this.distance();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 463;
	            this.location_modifier();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	location_modifier() {
	    let localctx = new Location_modifierContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 98, BehaviorParser.RULE_location_modifier);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 466;
	        this.match(BehaviorParser.IN);
	        this.state = 467;
	        this.match(BehaviorParser.LOC_NAME);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	id_list() {
	    let localctx = new Id_listContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 100, BehaviorParser.RULE_id_list);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 469;
	        _la = this._input.LA(1);
	        if(!(_la===80 || _la===130)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	        this.state = 476;
	        this._errHandler.sync(this);
	        var _alt = this._interp.adaptivePredict(this._input,33,this._ctx)
	        while(_alt!=2 && _alt!=antlr4.atn.ATN.INVALID_ALT_NUMBER) {
	            if(_alt===1) {
	                this.state = 471;
	                this._errHandler.sync(this);
	                _la = this._input.LA(1);
	                if(_la===13) {
	                    this.state = 470;
	                    this.match(BehaviorParser.COMMA);
	                }

	                this.state = 473;
	                _la = this._input.LA(1);
	                if(!(_la===80 || _la===130)) {
	                this._errHandler.recoverInline(this);
	                }
	                else {
	                	this._errHandler.reportMatch(this);
	                    this.consume();
	                } 
	            }
	            this.state = 478;
	            this._errHandler.sync(this);
	            _alt = this._interp.adaptivePredict(this._input,33,this._ctx);
	        }

	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	distance() {
	    let localctx = new DistanceContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 102, BehaviorParser.RULE_distance);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 479;
	        this.match(BehaviorParser.WITHIN);
	        this.state = 480;
	        this.value_numeric();
	        this.state = 481;
	        _la = this._input.LA(1);
	        if(!(_la===7 || _la===8)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	direction() {
	    let localctx = new DirectionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 104, BehaviorParser.RULE_direction);
	    try {
	        this.state = 485;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 55:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 483;
	            this.front();
	            break;
	        case 27:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 484;
	            this.behind();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	front() {
	    let localctx = new FrontContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 106, BehaviorParser.RULE_front);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 487;
	        this.match(BehaviorParser.IN);
	        this.state = 488;
	        this.match(BehaviorParser.FRONT);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	behind() {
	    let localctx = new BehindContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 108, BehaviorParser.RULE_behind);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 490;
	        this.match(BehaviorParser.BEHIND);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	ped_Selector() {
	    let localctx = new Ped_SelectorContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 110, BehaviorParser.RULE_ped_Selector);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 492;
	        this.match(BehaviorParser.SELECTOR);
	        this.state = 493;
	        this.match(BehaviorParser.COLON);
	        this.state = 497;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===48 || _la===87 || _la===94 || _la===119) {
	            this.state = 494;
	            this.selector_attribute();
	            this.state = 499;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_attribute() {
	    let localctx = new Selector_attributeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 112, BehaviorParser.RULE_selector_attribute);
	    try {
	        this.state = 504;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 119:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 500;
	            this.selector_type();
	            break;
	        case 94:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 501;
	            this.selector_selector();
	            break;
	        case 48:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 502;
	            this.selector_from();
	            break;
	        case 87:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 503;
	            this.selector_required();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_type() {
	    let localctx = new Selector_typeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 114, BehaviorParser.RULE_selector_type);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 506;
	        this.match(BehaviorParser.TYPE);
	        this.state = 507;
	        this.match(BehaviorParser.COLON);
	        this.state = 508;
	        this.id_list();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_selector() {
	    let localctx = new Selector_selectorContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 116, BehaviorParser.RULE_selector_selector);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 510;
	        this.match(BehaviorParser.SELECT);
	        this.state = 511;
	        this.match(BehaviorParser.COLON);
	        this.state = 512;
	        this.selector();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_from() {
	    let localctx = new Selector_fromContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 118, BehaviorParser.RULE_selector_from);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 514;
	        this.match(BehaviorParser.FROM);
	        this.state = 515;
	        this.match(BehaviorParser.COLON);
	        this.state = 516;
	        this.group();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_required() {
	    let localctx = new Selector_requiredContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 120, BehaviorParser.RULE_selector_required);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 518;
	        this.match(BehaviorParser.REQUIRED);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector() {
	    let localctx = new SelectorContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 122, BehaviorParser.RULE_selector);
	    try {
	        this.state = 524;
	        this._errHandler.sync(this);
	        var la_ = this._interp.adaptivePredict(this._input,37,this._ctx);
	        switch(la_) {
	        case 1:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 520;
	            this.selector_Percent();
	            break;

	        case 2:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 521;
	            this.selector_Exactly_N_Random();
	            break;

	        case 3:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 522;
	            this.selector_Everyone();
	            break;

	        case 4:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 523;
	            this.selector_Location();
	            break;

	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_Percent() {
	    let localctx = new Selector_PercentContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 124, BehaviorParser.RULE_selector_Percent);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 526;
	        this.value_numeric();
	        this.state = 527;
	        this.match(BehaviorParser.T__8);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_Exactly_N_Random() {
	    let localctx = new Selector_Exactly_N_RandomContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 126, BehaviorParser.RULE_selector_Exactly_N_Random);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 529;
	        this.value_numeric();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_Everyone() {
	    let localctx = new Selector_EveryoneContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 128, BehaviorParser.RULE_selector_Everyone);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 531;
	        this.match(BehaviorParser.EVERYONE);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	selector_Location() {
	    let localctx = new Selector_LocationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 130, BehaviorParser.RULE_selector_Location);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 533;
	        this.match(BehaviorParser.IN);
	        this.state = 534;
	        this.match(BehaviorParser.LOC_NAME);
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	group() {
	    let localctx = new GroupContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 132, BehaviorParser.RULE_group);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 536;
	        _la = this._input.LA(1);
	        if(!(_la===80 || _la===81 || _la===130)) {
	        this._errHandler.recoverInline(this);
	        }
	        else {
	        	this._errHandler.reportMatch(this);
	            this.consume();
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action() {
	    let localctx = new ActionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 134, BehaviorParser.RULE_action);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 538;
	        this.match(BehaviorParser.ACTION);
	        this.state = 539;
	        this.match(BehaviorParser.T__2);
	        this.state = 540;
	        this.match(BehaviorParser.ID);
	        this.state = 541;
	        this.match(BehaviorParser.T__3);
	        this.state = 542;
	        this.match(BehaviorParser.COLON);
	        this.state = 546;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===32 || _la===35 || _la===90 || _la===110) {
	            this.state = 543;
	            this.action_attribute();
	            this.state = 548;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_attribute() {
	    let localctx = new Action_attributeContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 136, BehaviorParser.RULE_action_attribute);
	    try {
	        this.state = 553;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 90:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 549;
	            this.action_response();
	            break;
	        case 32:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 550;
	            this.action_stimulus();
	            break;
	        case 35:
	            this.enterOuterAlt(localctx, 3);
	            this.state = 551;
	            this.action_duration();
	            break;
	        case 110:
	            this.enterOuterAlt(localctx, 4);
	            this.state = 552;
	            this.action_target();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_stimulus() {
	    let localctx = new Action_stimulusContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 138, BehaviorParser.RULE_action_stimulus);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 555;
	        this.match(BehaviorParser.CONDITION);
	        this.state = 556;
	        this.match(BehaviorParser.COLON);
	        this.state = 557;
	        this.condition();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_response() {
	    let localctx = new Action_responseContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 140, BehaviorParser.RULE_action_response);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 559;
	        this.match(BehaviorParser.RESPONSE);
	        this.state = 560;
	        this.match(BehaviorParser.COLON);
	        this.state = 561;
	        this.sub_action();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_duration() {
	    let localctx = new Action_durationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 142, BehaviorParser.RULE_action_duration);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 563;
	        this.match(BehaviorParser.DURATION);
	        this.state = 564;
	        this.match(BehaviorParser.COLON);
	        this.state = 565;
	        this.duration();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_target() {
	    let localctx = new Action_targetContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 144, BehaviorParser.RULE_action_target);
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 567;
	        this.match(BehaviorParser.TARGET);
	        this.state = 568;
	        this.match(BehaviorParser.COLON);
	        this.state = 569;
	        this.target();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	sub_action() {
	    let localctx = new Sub_actionContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 146, BehaviorParser.RULE_sub_action);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 571;
	        this.action_atom();
	        this.state = 576;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===13) {
	            this.state = 572;
	            this.match(BehaviorParser.COMMA);
	            this.state = 573;
	            this.action_atom();
	            this.state = 578;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	action_atom() {
	    let localctx = new Action_atomContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 148, BehaviorParser.RULE_action_atom);
	    try {
	        this.state = 581;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 96:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 579;
	            this.set_atom();
	            break;
	        case 92:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 580;
	            this.scale_atom();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	set_atom() {
	    let localctx = new Set_atomContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 150, BehaviorParser.RULE_set_atom);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 583;
	        this.match(BehaviorParser.SET);
	        this.state = 585;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===110) {
	            this.state = 584;
	            this.match(BehaviorParser.TARGET);
	        }

	        this.state = 587;
	        this.attribute();
	        this.state = 588;
	        this.match(BehaviorParser.TO);
	        this.state = 589;
	        this.attr_value();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	scale_atom() {
	    let localctx = new Scale_atomContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 152, BehaviorParser.RULE_scale_atom);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 591;
	        this.match(BehaviorParser.SCALE);
	        this.state = 593;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        if(_la===110) {
	            this.state = 592;
	            this.match(BehaviorParser.TARGET);
	        }

	        this.state = 595;
	        this.attribute();
	        this.state = 596;
	        this.attr_value();
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	declaration() {
	    let localctx = new DeclarationContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 154, BehaviorParser.RULE_declaration);
	    try {
	        this.state = 600;
	        this._errHandler.sync(this);
	        switch(this._input.LA(1)) {
	        case 103:
	            this.enterOuterAlt(localctx, 1);
	            this.state = 598;
	            this.decl_Ped_State();
	            break;
	        case 118:
	            this.enterOuterAlt(localctx, 2);
	            this.state = 599;
	            this.decl_Ped();
	            break;
	        default:
	            throw new antlr4.error.NoViableAltException(this);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	decl_Ped_State() {
	    let localctx = new Decl_Ped_StateContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 156, BehaviorParser.RULE_decl_Ped_State);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 602;
	        this.match(BehaviorParser.STATES);
	        this.state = 603;
	        this.match(BehaviorParser.COLON);
	        this.state = 604;
	        this.match(BehaviorParser.ID);
	        this.state = 611;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===13 || _la===130) {
	            this.state = 606;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===13) {
	                this.state = 605;
	                this.match(BehaviorParser.COMMA);
	            }

	            this.state = 608;
	            this.match(BehaviorParser.ID);
	            this.state = 613;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}



	decl_Ped() {
	    let localctx = new Decl_PedContext(this, this._ctx, this.state);
	    this.enterRule(localctx, 158, BehaviorParser.RULE_decl_Ped);
	    var _la = 0;
	    try {
	        this.enterOuterAlt(localctx, 1);
	        this.state = 614;
	        this.match(BehaviorParser.TYPES);
	        this.state = 615;
	        this.match(BehaviorParser.COLON);
	        this.state = 616;
	        this.match(BehaviorParser.ID);
	        this.state = 623;
	        this._errHandler.sync(this);
	        _la = this._input.LA(1);
	        while(_la===13 || _la===130) {
	            this.state = 618;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	            if(_la===13) {
	                this.state = 617;
	                this.match(BehaviorParser.COMMA);
	            }

	            this.state = 620;
	            this.match(BehaviorParser.ID);
	            this.state = 625;
	            this._errHandler.sync(this);
	            _la = this._input.LA(1);
	        }
	    } catch (re) {
	    	if(re instanceof antlr4.error.RecognitionException) {
		        localctx.exception = re;
		        this._errHandler.reportError(this, re);
		        this._errHandler.recover(this, re);
		    } else {
		    	throw re;
		    }
	    } finally {
	        this.exitRule();
	    }
	    return localctx;
	}


}

BehaviorParser.EOF = antlr4.Token.EOF;
BehaviorParser.T__0 = 1;
BehaviorParser.T__1 = 2;
BehaviorParser.T__2 = 3;
BehaviorParser.T__3 = 4;
BehaviorParser.T__4 = 5;
BehaviorParser.T__5 = 6;
BehaviorParser.T__6 = 7;
BehaviorParser.T__7 = 8;
BehaviorParser.T__8 = 9;
BehaviorParser.COMMENT = 10;
BehaviorParser.LINE_COMMENT = 11;
BehaviorParser.WS = 12;
BehaviorParser.COMMA = 13;
BehaviorParser.COLON = 14;
BehaviorParser.STATE_VAL = 15;
BehaviorParser.EVNT = 16;
BehaviorParser.LOC_NAME = 17;
BehaviorParser.AN = 18;
BehaviorParser.AT = 19;
BehaviorParser.ADD = 20;
BehaviorParser.AND = 21;
BehaviorParser.ARE = 22;
BehaviorParser.AFTER = 23;
BehaviorParser.ALWAYS = 24;
BehaviorParser.AROUND = 25;
BehaviorParser.ACTION = 26;
BehaviorParser.BEHIND = 27;
BehaviorParser.BOTTOM = 28;
BehaviorParser.CONSIDER = 29;
BehaviorParser.CIRCLE = 30;
BehaviorParser.CENTER = 31;
BehaviorParser.CONDITION = 32;
BehaviorParser.DECIMAL = 33;
BehaviorParser.DIRECTION = 34;
BehaviorParser.DURATION = 35;
BehaviorParser.END = 36;
BehaviorParser.ENDS = 37;
BehaviorParser.ENVIRONMENT = 38;
BehaviorParser.ENTER = 39;
BehaviorParser.EXIT = 40;
BehaviorParser.EXISTS = 41;
BehaviorParser.EVENT = 42;
BehaviorParser.EXACTLY = 43;
BehaviorParser.EVERY = 44;
BehaviorParser.EVERYONE = 45;
BehaviorParser.ENDING = 46;
BehaviorParser.FOR = 47;
BehaviorParser.FROM = 48;
BehaviorParser.FRONT = 49;
BehaviorParser.FASTER = 50;
BehaviorParser.GIVEN = 51;
BehaviorParser.GOAL = 52;
BehaviorParser.GROUP = 53;
BehaviorParser.HAS = 54;
BehaviorParser.IN = 55;
BehaviorParser.INTEGER = 56;
BehaviorParser.INITIAL = 57;
BehaviorParser.IS = 58;
BehaviorParser.IF = 59;
BehaviorParser.LENGTH = 60;
BehaviorParser.LENGTHS = 61;
BehaviorParser.LEFT = 62;
BehaviorParser.LEAVE = 63;
BehaviorParser.DIMENSIONS = 64;
BehaviorParser.LOCATION = 65;
BehaviorParser.MAX = 66;
BehaviorParser.METERS = 67;
BehaviorParser.NAME = 68;
BehaviorParser.NEAREST = 69;
BehaviorParser.NORMAL = 70;
BehaviorParser.NONE = 71;
BehaviorParser.NOT = 72;
BehaviorParser.OBSTACLE = 73;
BehaviorParser.OCCUR = 74;
BehaviorParser.OCCURRED = 75;
BehaviorParser.OCCURRING = 76;
BehaviorParser.OF = 77;
BehaviorParser.ON = 78;
BehaviorParser.OR = 79;
BehaviorParser.PEDESTRIAN = 80;
BehaviorParser.PEDESTRIANS = 81;
BehaviorParser.POINTS = 82;
BehaviorParser.POSSIBLE = 83;
BehaviorParser.POSITION = 84;
BehaviorParser.RADIUS = 85;
BehaviorParser.RANDOM = 86;
BehaviorParser.REQUIRED = 87;
BehaviorParser.RECTANGLE = 88;
BehaviorParser.RIGHT = 89;
BehaviorParser.RESPONSE = 90;
BehaviorParser.ROTATION = 91;
BehaviorParser.SCALE = 92;
BehaviorParser.SELF = 93;
BehaviorParser.SELECT = 94;
BehaviorParser.SELECTOR = 95;
BehaviorParser.SET = 96;
BehaviorParser.SECOND = 97;
BehaviorParser.SECONDS = 98;
BehaviorParser.STATUS = 99;
BehaviorParser.STARTING = 100;
BehaviorParser.SLOWER = 101;
BehaviorParser.SHAPE = 102;
BehaviorParser.STATES = 103;
BehaviorParser.START = 104;
BehaviorParser.STATE = 105;
BehaviorParser.STARTS = 106;
BehaviorParser.SPEED = 107;
BehaviorParser.SUBTRACT = 108;
BehaviorParser.STIMULUS = 109;
BehaviorParser.TARGET = 110;
BehaviorParser.TO = 111;
BehaviorParser.TOP = 112;
BehaviorParser.THE = 113;
BehaviorParser.THAT = 114;
BehaviorParser.THEN = 115;
BehaviorParser.THEIR = 116;
BehaviorParser.THERES = 117;
BehaviorParser.TYPES = 118;
BehaviorParser.TYPE = 119;
BehaviorParser.UNCONDITIONALLY = 120;
BehaviorParser.VALUE = 121;
BehaviorParser.VELOCITY = 122;
BehaviorParser.WHEN = 123;
BehaviorParser.WHILE = 124;
BehaviorParser.WHOSE = 125;
BehaviorParser.WIDTH = 126;
BehaviorParser.WILL = 127;
BehaviorParser.WITH = 128;
BehaviorParser.WITHIN = 129;
BehaviorParser.ID = 130;
BehaviorParser.NUMBER = 131;
BehaviorParser.FLOAT = 132;

BehaviorParser.RULE_program = 0;
BehaviorParser.RULE_statement = 1;
BehaviorParser.RULE_location = 2;
BehaviorParser.RULE_location_attribute = 3;
BehaviorParser.RULE_loc_name = 4;
BehaviorParser.RULE_loc_dimensions = 5;
BehaviorParser.RULE_loc_center = 6;
BehaviorParser.RULE_loc_lengths = 7;
BehaviorParser.RULE_loc_rotation = 8;
BehaviorParser.RULE_value_numeric = 9;
BehaviorParser.RULE_value_number = 10;
BehaviorParser.RULE_value_float = 11;
BehaviorParser.RULE_value_range = 12;
BehaviorParser.RULE_value_random = 13;
BehaviorParser.RULE_random_number = 14;
BehaviorParser.RULE_random_float = 15;
BehaviorParser.RULE_float_range = 16;
BehaviorParser.RULE_number_range = 17;
BehaviorParser.RULE_value_coord = 18;
BehaviorParser.RULE_event = 19;
BehaviorParser.RULE_event_attribute = 20;
BehaviorParser.RULE_event_name = 21;
BehaviorParser.RULE_event_start = 22;
BehaviorParser.RULE_event_end = 23;
BehaviorParser.RULE_condition = 24;
BehaviorParser.RULE_connector = 25;
BehaviorParser.RULE_or_Connector = 26;
BehaviorParser.RULE_and_Connector = 27;
BehaviorParser.RULE_sub_condition = 28;
BehaviorParser.RULE_condition_Time_Elapsed_From_Event = 29;
BehaviorParser.RULE_condition_Event_Occurred = 30;
BehaviorParser.RULE_condition_Event_Occurring = 31;
BehaviorParser.RULE_condition_Event_Starting = 32;
BehaviorParser.RULE_condition_Event_Ending = 33;
BehaviorParser.RULE_condition_Spatial = 34;
BehaviorParser.RULE_condition_Enter_Location = 35;
BehaviorParser.RULE_condition_Exit_Location = 36;
BehaviorParser.RULE_condition_Inside_Location = 37;
BehaviorParser.RULE_condition_Attribute = 38;
BehaviorParser.RULE_condition_Exists = 39;
BehaviorParser.RULE_duration = 40;
BehaviorParser.RULE_attribute = 41;
BehaviorParser.RULE_attr_value = 42;
BehaviorParser.RULE_end_goal = 43;
BehaviorParser.RULE_target = 44;
BehaviorParser.RULE_self = 45;
BehaviorParser.RULE_other = 46;
BehaviorParser.RULE_nearest_type = 47;
BehaviorParser.RULE_modifier = 48;
BehaviorParser.RULE_location_modifier = 49;
BehaviorParser.RULE_id_list = 50;
BehaviorParser.RULE_distance = 51;
BehaviorParser.RULE_direction = 52;
BehaviorParser.RULE_front = 53;
BehaviorParser.RULE_behind = 54;
BehaviorParser.RULE_ped_Selector = 55;
BehaviorParser.RULE_selector_attribute = 56;
BehaviorParser.RULE_selector_type = 57;
BehaviorParser.RULE_selector_selector = 58;
BehaviorParser.RULE_selector_from = 59;
BehaviorParser.RULE_selector_required = 60;
BehaviorParser.RULE_selector = 61;
BehaviorParser.RULE_selector_Percent = 62;
BehaviorParser.RULE_selector_Exactly_N_Random = 63;
BehaviorParser.RULE_selector_Everyone = 64;
BehaviorParser.RULE_selector_Location = 65;
BehaviorParser.RULE_group = 66;
BehaviorParser.RULE_action = 67;
BehaviorParser.RULE_action_attribute = 68;
BehaviorParser.RULE_action_stimulus = 69;
BehaviorParser.RULE_action_response = 70;
BehaviorParser.RULE_action_duration = 71;
BehaviorParser.RULE_action_target = 72;
BehaviorParser.RULE_sub_action = 73;
BehaviorParser.RULE_action_atom = 74;
BehaviorParser.RULE_set_atom = 75;
BehaviorParser.RULE_scale_atom = 76;
BehaviorParser.RULE_declaration = 77;
BehaviorParser.RULE_decl_Ped_State = 78;
BehaviorParser.RULE_decl_Ped = 79;

class ProgramContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_program;
    }

	decl_Ped() {
	    return this.getTypedRuleContext(Decl_PedContext,0);
	};

	statement = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(StatementContext);
	    } else {
	        return this.getTypedRuleContext(StatementContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitProgram(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class StatementContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_statement;
    }

	event() {
	    return this.getTypedRuleContext(EventContext,0);
	};

	location() {
	    return this.getTypedRuleContext(LocationContext,0);
	};

	declaration() {
	    return this.getTypedRuleContext(DeclarationContext,0);
	};

	ped_Selector() {
	    return this.getTypedRuleContext(Ped_SelectorContext,0);
	};

	action() {
	    return this.getTypedRuleContext(ActionContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitStatement(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class LocationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_location;
    }

	LOCATION() {
	    return this.getToken(BehaviorParser.LOCATION, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	location_attribute = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Location_attributeContext);
	    } else {
	        return this.getTypedRuleContext(Location_attributeContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitLocation(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Location_attributeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_location_attribute;
    }

	loc_name() {
	    return this.getTypedRuleContext(Loc_nameContext,0);
	};

	loc_dimensions() {
	    return this.getTypedRuleContext(Loc_dimensionsContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitLocation_attribute(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Loc_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_loc_name;
    }

	NAME() {
	    return this.getToken(BehaviorParser.NAME, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	ID() {
	    return this.getToken(BehaviorParser.ID, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitLoc_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Loc_dimensionsContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_loc_dimensions;
    }

	DIMENSIONS() {
	    return this.getToken(BehaviorParser.DIMENSIONS, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	loc_center() {
	    return this.getTypedRuleContext(Loc_centerContext,0);
	};

	loc_lengths() {
	    return this.getTypedRuleContext(Loc_lengthsContext,0);
	};

	loc_rotation() {
	    return this.getTypedRuleContext(Loc_rotationContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitLoc_dimensions(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Loc_centerContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_loc_center;
    }

	CENTER() {
	    return this.getToken(BehaviorParser.CENTER, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	value_coord() {
	    return this.getTypedRuleContext(Value_coordContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitLoc_center(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Loc_lengthsContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_loc_lengths;
    }

	LENGTHS() {
	    return this.getToken(BehaviorParser.LENGTHS, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	value_coord() {
	    return this.getTypedRuleContext(Value_coordContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitLoc_lengths(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Loc_rotationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_loc_rotation;
    }

	ROTATION() {
	    return this.getToken(BehaviorParser.ROTATION, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	value_numeric() {
	    return this.getTypedRuleContext(Value_numericContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitLoc_rotation(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Value_numericContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_value_numeric;
    }

	value_number() {
	    return this.getTypedRuleContext(Value_numberContext,0);
	};

	value_float() {
	    return this.getTypedRuleContext(Value_floatContext,0);
	};

	value_range() {
	    return this.getTypedRuleContext(Value_rangeContext,0);
	};

	value_random() {
	    return this.getTypedRuleContext(Value_randomContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitValue_numeric(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Value_numberContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_value_number;
    }

	NUMBER() {
	    return this.getToken(BehaviorParser.NUMBER, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitValue_number(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Value_floatContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_value_float;
    }

	FLOAT() {
	    return this.getToken(BehaviorParser.FLOAT, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitValue_float(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Value_rangeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_value_range;
    }

	float_range() {
	    return this.getTypedRuleContext(Float_rangeContext,0);
	};

	number_range() {
	    return this.getTypedRuleContext(Number_rangeContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitValue_range(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Value_randomContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_value_random;
    }

	random_float() {
	    return this.getTypedRuleContext(Random_floatContext,0);
	};

	random_number() {
	    return this.getTypedRuleContext(Random_numberContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitValue_random(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Random_numberContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_random_number;
    }

	RANDOM() {
	    return this.getToken(BehaviorParser.RANDOM, 0);
	};

	number_range() {
	    return this.getTypedRuleContext(Number_rangeContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitRandom_number(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Random_floatContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_random_float;
    }

	RANDOM() {
	    return this.getToken(BehaviorParser.RANDOM, 0);
	};

	float_range() {
	    return this.getTypedRuleContext(Float_rangeContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitRandom_float(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Float_rangeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_float_range;
    }

	value_float = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Value_floatContext);
	    } else {
	        return this.getTypedRuleContext(Value_floatContext,i);
	    }
	};

	TO() {
	    return this.getToken(BehaviorParser.TO, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitFloat_range(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Number_rangeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_number_range;
    }

	value_number = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Value_numberContext);
	    } else {
	        return this.getTypedRuleContext(Value_numberContext,i);
	    }
	};

	TO() {
	    return this.getToken(BehaviorParser.TO, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitNumber_range(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Value_coordContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_value_coord;
    }

	value_numeric = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Value_numericContext);
	    } else {
	        return this.getTypedRuleContext(Value_numericContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.COMMA);
	    } else {
	        return this.getToken(BehaviorParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitValue_coord(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class EventContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_event;
    }

	EVENT() {
	    return this.getToken(BehaviorParser.EVENT, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	event_attribute = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Event_attributeContext);
	    } else {
	        return this.getTypedRuleContext(Event_attributeContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitEvent(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Event_attributeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_event_attribute;
    }

	event_name() {
	    return this.getTypedRuleContext(Event_nameContext,0);
	};

	event_start() {
	    return this.getTypedRuleContext(Event_startContext,0);
	};

	event_end() {
	    return this.getTypedRuleContext(Event_endContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitEvent_attribute(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Event_nameContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_event_name;
    }

	NAME() {
	    return this.getToken(BehaviorParser.NAME, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	ID() {
	    return this.getToken(BehaviorParser.ID, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitEvent_name(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Event_startContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_event_start;
    }

	START() {
	    return this.getToken(BehaviorParser.START, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	condition() {
	    return this.getTypedRuleContext(ConditionContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitEvent_start(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Event_endContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_event_end;
    }

	END() {
	    return this.getToken(BehaviorParser.END, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	condition() {
	    return this.getTypedRuleContext(ConditionContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitEvent_end(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ConditionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition;
    }

	sub_condition = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Sub_conditionContext);
	    } else {
	        return this.getTypedRuleContext(Sub_conditionContext,i);
	    }
	};

	connector = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ConnectorContext);
	    } else {
	        return this.getTypedRuleContext(ConnectorContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ConnectorContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_connector;
    }

	or_Connector() {
	    return this.getTypedRuleContext(Or_ConnectorContext,0);
	};

	and_Connector() {
	    return this.getTypedRuleContext(And_ConnectorContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitConnector(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Or_ConnectorContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_or_Connector;
    }

	OR() {
	    return this.getToken(BehaviorParser.OR, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitOr_Connector(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class And_ConnectorContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_and_Connector;
    }

	AND() {
	    return this.getToken(BehaviorParser.AND, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAnd_Connector(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Sub_conditionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_sub_condition;
    }

	condition_Time_Elapsed_From_Event() {
	    return this.getTypedRuleContext(Condition_Time_Elapsed_From_EventContext,0);
	};

	condition_Event_Occurred() {
	    return this.getTypedRuleContext(Condition_Event_OccurredContext,0);
	};

	condition_Event_Occurring() {
	    return this.getTypedRuleContext(Condition_Event_OccurringContext,0);
	};

	condition_Event_Starting() {
	    return this.getTypedRuleContext(Condition_Event_StartingContext,0);
	};

	condition_Event_Ending() {
	    return this.getTypedRuleContext(Condition_Event_EndingContext,0);
	};

	condition_Spatial() {
	    return this.getTypedRuleContext(Condition_SpatialContext,0);
	};

	condition_Enter_Location() {
	    return this.getTypedRuleContext(Condition_Enter_LocationContext,0);
	};

	condition_Exit_Location() {
	    return this.getTypedRuleContext(Condition_Exit_LocationContext,0);
	};

	condition_Inside_Location() {
	    return this.getTypedRuleContext(Condition_Inside_LocationContext,0);
	};

	condition_Attribute() {
	    return this.getTypedRuleContext(Condition_AttributeContext,0);
	};

	condition_Exists() {
	    return this.getTypedRuleContext(Condition_ExistsContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSub_condition(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_Time_Elapsed_From_EventContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Time_Elapsed_From_Event;
    }

	value_numeric() {
	    return this.getTypedRuleContext(Value_numericContext,0);
	};

	SECONDS() {
	    return this.getToken(BehaviorParser.SECONDS, 0);
	};

	AFTER() {
	    return this.getToken(BehaviorParser.AFTER, 0);
	};

	EVNT() {
	    return this.getToken(BehaviorParser.EVNT, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Time_Elapsed_From_Event(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_Event_OccurredContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Event_Occurred;
    }

	EVNT() {
	    return this.getToken(BehaviorParser.EVNT, 0);
	};

	HAS() {
	    return this.getToken(BehaviorParser.HAS, 0);
	};

	OCCURRED() {
	    return this.getToken(BehaviorParser.OCCURRED, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Event_Occurred(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_Event_OccurringContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Event_Occurring;
    }

	EVNT() {
	    return this.getToken(BehaviorParser.EVNT, 0);
	};

	OCCURRING() {
	    return this.getToken(BehaviorParser.OCCURRING, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Event_Occurring(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_Event_StartingContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Event_Starting;
    }

	EVNT() {
	    return this.getToken(BehaviorParser.EVNT, 0);
	};

	STARTING() {
	    return this.getToken(BehaviorParser.STARTING, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Event_Starting(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_Event_EndingContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Event_Ending;
    }

	EVNT() {
	    return this.getToken(BehaviorParser.EVNT, 0);
	};

	ENDING() {
	    return this.getToken(BehaviorParser.ENDING, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Event_Ending(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_SpatialContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Spatial;
    }

	TARGET() {
	    return this.getToken(BehaviorParser.TARGET, 0);
	};

	WITHIN() {
	    return this.getToken(BehaviorParser.WITHIN, 0);
	};

	value_numeric() {
	    return this.getTypedRuleContext(Value_numericContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Spatial(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_Enter_LocationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Enter_Location;
    }

	ENTER() {
	    return this.getToken(BehaviorParser.ENTER, 0);
	};

	LOC_NAME() {
	    return this.getToken(BehaviorParser.LOC_NAME, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Enter_Location(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_Exit_LocationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Exit_Location;
    }

	LEAVE() {
	    return this.getToken(BehaviorParser.LEAVE, 0);
	};

	LOC_NAME() {
	    return this.getToken(BehaviorParser.LOC_NAME, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Exit_Location(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_Inside_LocationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Inside_Location;
    }

	IN() {
	    return this.getToken(BehaviorParser.IN, 0);
	};

	LOC_NAME() {
	    return this.getToken(BehaviorParser.LOC_NAME, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Inside_Location(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_AttributeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Attribute;
    }

	attribute() {
	    return this.getTypedRuleContext(AttributeContext,0);
	};

	IS() {
	    return this.getToken(BehaviorParser.IS, 0);
	};

	attr_value() {
	    return this.getTypedRuleContext(Attr_valueContext,0);
	};

	TARGET() {
	    return this.getToken(BehaviorParser.TARGET, 0);
	};

	NOT() {
	    return this.getToken(BehaviorParser.NOT, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Attribute(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Condition_ExistsContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_condition_Exists;
    }

	EXISTS() {
	    return this.getToken(BehaviorParser.EXISTS, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	ID() {
	    return this.getToken(BehaviorParser.ID, 0);
	};

	WHOSE() {
	    return this.getToken(BehaviorParser.WHOSE, 0);
	};

	attribute() {
	    return this.getTypedRuleContext(AttributeContext,0);
	};

	IS() {
	    return this.getToken(BehaviorParser.IS, 0);
	};

	attr_value() {
	    return this.getTypedRuleContext(Attr_valueContext,0);
	};

	modifier = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ModifierContext);
	    } else {
	        return this.getTypedRuleContext(ModifierContext,i);
	    }
	};

	NOT() {
	    return this.getToken(BehaviorParser.NOT, 0);
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.COMMA);
	    } else {
	        return this.getToken(BehaviorParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitCondition_Exists(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DurationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_duration;
    }

	value_numeric() {
	    return this.getTypedRuleContext(Value_numericContext,0);
	};

	SECONDS() {
	    return this.getToken(BehaviorParser.SECONDS, 0);
	};

	SECOND() {
	    return this.getToken(BehaviorParser.SECOND, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitDuration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class AttributeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_attribute;
    }

	POSITION() {
	    return this.getToken(BehaviorParser.POSITION, 0);
	};

	VELOCITY() {
	    return this.getToken(BehaviorParser.VELOCITY, 0);
	};

	GOAL() {
	    return this.getToken(BehaviorParser.GOAL, 0);
	};

	end_goal() {
	    return this.getTypedRuleContext(End_goalContext,0);
	};

	STATE() {
	    return this.getToken(BehaviorParser.STATE, 0);
	};

	LOCATION() {
	    return this.getToken(BehaviorParser.LOCATION, 0);
	};

	STATUS() {
	    return this.getToken(BehaviorParser.STATUS, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAttribute(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Attr_valueContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_attr_value;
    }

	value_numeric() {
	    return this.getTypedRuleContext(Value_numericContext,0);
	};

	value_coord() {
	    return this.getTypedRuleContext(Value_coordContext,0);
	};

	STATE_VAL() {
	    return this.getToken(BehaviorParser.STATE_VAL, 0);
	};

	LOC_NAME() {
	    return this.getToken(BehaviorParser.LOC_NAME, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAttr_value(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class End_goalContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_end_goal;
    }

	END() {
	    return this.getToken(BehaviorParser.END, 0);
	};

	GOAL() {
	    return this.getToken(BehaviorParser.GOAL, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitEnd_goal(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class TargetContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_target;
    }

	self() {
	    return this.getTypedRuleContext(SelfContext,0);
	};

	other() {
	    return this.getTypedRuleContext(OtherContext,0);
	};

	modifier = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(ModifierContext);
	    } else {
	        return this.getTypedRuleContext(ModifierContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.COMMA);
	    } else {
	        return this.getToken(BehaviorParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitTarget(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SelfContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_self;
    }

	SELF() {
	    return this.getToken(BehaviorParser.SELF, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelf(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class OtherContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_other;
    }

	nearest_type() {
	    return this.getTypedRuleContext(Nearest_typeContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitOther(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Nearest_typeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_nearest_type;
    }

	NEAREST() {
	    return this.getToken(BehaviorParser.NEAREST, 0);
	};

	id_list() {
	    return this.getTypedRuleContext(Id_listContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitNearest_type(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ModifierContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_modifier;
    }

	direction() {
	    return this.getTypedRuleContext(DirectionContext,0);
	};

	distance() {
	    return this.getTypedRuleContext(DistanceContext,0);
	};

	location_modifier() {
	    return this.getTypedRuleContext(Location_modifierContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitModifier(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Location_modifierContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_location_modifier;
    }

	IN() {
	    return this.getToken(BehaviorParser.IN, 0);
	};

	LOC_NAME() {
	    return this.getToken(BehaviorParser.LOC_NAME, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitLocation_modifier(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Id_listContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_id_list;
    }

	ID = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.ID);
	    } else {
	        return this.getToken(BehaviorParser.ID, i);
	    }
	};


	PEDESTRIAN = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.PEDESTRIAN);
	    } else {
	        return this.getToken(BehaviorParser.PEDESTRIAN, i);
	    }
	};


	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.COMMA);
	    } else {
	        return this.getToken(BehaviorParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitId_list(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DistanceContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_distance;
    }

	WITHIN() {
	    return this.getToken(BehaviorParser.WITHIN, 0);
	};

	value_numeric() {
	    return this.getTypedRuleContext(Value_numericContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitDistance(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DirectionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_direction;
    }

	front() {
	    return this.getTypedRuleContext(FrontContext,0);
	};

	behind() {
	    return this.getTypedRuleContext(BehindContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitDirection(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class FrontContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_front;
    }

	IN() {
	    return this.getToken(BehaviorParser.IN, 0);
	};

	FRONT() {
	    return this.getToken(BehaviorParser.FRONT, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitFront(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class BehindContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_behind;
    }

	BEHIND() {
	    return this.getToken(BehaviorParser.BEHIND, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitBehind(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Ped_SelectorContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_ped_Selector;
    }

	SELECTOR() {
	    return this.getToken(BehaviorParser.SELECTOR, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	selector_attribute = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Selector_attributeContext);
	    } else {
	        return this.getTypedRuleContext(Selector_attributeContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitPed_Selector(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_attributeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_attribute;
    }

	selector_type() {
	    return this.getTypedRuleContext(Selector_typeContext,0);
	};

	selector_selector() {
	    return this.getTypedRuleContext(Selector_selectorContext,0);
	};

	selector_from() {
	    return this.getTypedRuleContext(Selector_fromContext,0);
	};

	selector_required() {
	    return this.getTypedRuleContext(Selector_requiredContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_attribute(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_typeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_type;
    }

	TYPE() {
	    return this.getToken(BehaviorParser.TYPE, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	id_list() {
	    return this.getTypedRuleContext(Id_listContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_type(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_selectorContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_selector;
    }

	SELECT() {
	    return this.getToken(BehaviorParser.SELECT, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	selector() {
	    return this.getTypedRuleContext(SelectorContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_selector(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_fromContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_from;
    }

	FROM() {
	    return this.getToken(BehaviorParser.FROM, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	group() {
	    return this.getTypedRuleContext(GroupContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_from(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_requiredContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_required;
    }

	REQUIRED() {
	    return this.getToken(BehaviorParser.REQUIRED, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_required(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class SelectorContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector;
    }

	selector_Percent() {
	    return this.getTypedRuleContext(Selector_PercentContext,0);
	};

	selector_Exactly_N_Random() {
	    return this.getTypedRuleContext(Selector_Exactly_N_RandomContext,0);
	};

	selector_Everyone() {
	    return this.getTypedRuleContext(Selector_EveryoneContext,0);
	};

	selector_Location() {
	    return this.getTypedRuleContext(Selector_LocationContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_PercentContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_Percent;
    }

	value_numeric() {
	    return this.getTypedRuleContext(Value_numericContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_Percent(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_Exactly_N_RandomContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_Exactly_N_Random;
    }

	value_numeric() {
	    return this.getTypedRuleContext(Value_numericContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_Exactly_N_Random(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_EveryoneContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_Everyone;
    }

	EVERYONE() {
	    return this.getToken(BehaviorParser.EVERYONE, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_Everyone(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Selector_LocationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_selector_Location;
    }

	IN() {
	    return this.getToken(BehaviorParser.IN, 0);
	};

	LOC_NAME() {
	    return this.getToken(BehaviorParser.LOC_NAME, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSelector_Location(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class GroupContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_group;
    }

	ID() {
	    return this.getToken(BehaviorParser.ID, 0);
	};

	PEDESTRIAN() {
	    return this.getToken(BehaviorParser.PEDESTRIAN, 0);
	};

	PEDESTRIANS() {
	    return this.getToken(BehaviorParser.PEDESTRIANS, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitGroup(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class ActionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_action;
    }

	ACTION() {
	    return this.getToken(BehaviorParser.ACTION, 0);
	};

	ID() {
	    return this.getToken(BehaviorParser.ID, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	action_attribute = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Action_attributeContext);
	    } else {
	        return this.getTypedRuleContext(Action_attributeContext,i);
	    }
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAction(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_attributeContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_action_attribute;
    }

	action_response() {
	    return this.getTypedRuleContext(Action_responseContext,0);
	};

	action_stimulus() {
	    return this.getTypedRuleContext(Action_stimulusContext,0);
	};

	action_duration() {
	    return this.getTypedRuleContext(Action_durationContext,0);
	};

	action_target() {
	    return this.getTypedRuleContext(Action_targetContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAction_attribute(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_stimulusContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_action_stimulus;
    }

	CONDITION() {
	    return this.getToken(BehaviorParser.CONDITION, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	condition() {
	    return this.getTypedRuleContext(ConditionContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAction_stimulus(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_responseContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_action_response;
    }

	RESPONSE() {
	    return this.getToken(BehaviorParser.RESPONSE, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	sub_action() {
	    return this.getTypedRuleContext(Sub_actionContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAction_response(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_durationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_action_duration;
    }

	DURATION() {
	    return this.getToken(BehaviorParser.DURATION, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	duration() {
	    return this.getTypedRuleContext(DurationContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAction_duration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_targetContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_action_target;
    }

	TARGET() {
	    return this.getToken(BehaviorParser.TARGET, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	target() {
	    return this.getTypedRuleContext(TargetContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAction_target(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Sub_actionContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_sub_action;
    }

	action_atom = function(i) {
	    if(i===undefined) {
	        i = null;
	    }
	    if(i===null) {
	        return this.getTypedRuleContexts(Action_atomContext);
	    } else {
	        return this.getTypedRuleContext(Action_atomContext,i);
	    }
	};

	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.COMMA);
	    } else {
	        return this.getToken(BehaviorParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSub_action(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Action_atomContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_action_atom;
    }

	set_atom() {
	    return this.getTypedRuleContext(Set_atomContext,0);
	};

	scale_atom() {
	    return this.getTypedRuleContext(Scale_atomContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitAction_atom(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Set_atomContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_set_atom;
    }

	SET() {
	    return this.getToken(BehaviorParser.SET, 0);
	};

	attribute() {
	    return this.getTypedRuleContext(AttributeContext,0);
	};

	TO() {
	    return this.getToken(BehaviorParser.TO, 0);
	};

	attr_value() {
	    return this.getTypedRuleContext(Attr_valueContext,0);
	};

	TARGET() {
	    return this.getToken(BehaviorParser.TARGET, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitSet_atom(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Scale_atomContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_scale_atom;
    }

	SCALE() {
	    return this.getToken(BehaviorParser.SCALE, 0);
	};

	attribute() {
	    return this.getTypedRuleContext(AttributeContext,0);
	};

	attr_value() {
	    return this.getTypedRuleContext(Attr_valueContext,0);
	};

	TARGET() {
	    return this.getToken(BehaviorParser.TARGET, 0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitScale_atom(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class DeclarationContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_declaration;
    }

	decl_Ped_State() {
	    return this.getTypedRuleContext(Decl_Ped_StateContext,0);
	};

	decl_Ped() {
	    return this.getTypedRuleContext(Decl_PedContext,0);
	};

	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitDeclaration(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Decl_Ped_StateContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_decl_Ped_State;
    }

	STATES() {
	    return this.getToken(BehaviorParser.STATES, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	ID = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.ID);
	    } else {
	        return this.getToken(BehaviorParser.ID, i);
	    }
	};


	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.COMMA);
	    } else {
	        return this.getToken(BehaviorParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitDecl_Ped_State(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}



class Decl_PedContext extends antlr4.ParserRuleContext {

    constructor(parser, parent, invokingState) {
        if(parent===undefined) {
            parent = null;
        }
        if(invokingState===undefined || invokingState===null) {
            invokingState = -1;
        }
        super(parent, invokingState);
        this.parser = parser;
        this.ruleIndex = BehaviorParser.RULE_decl_Ped;
    }

	TYPES() {
	    return this.getToken(BehaviorParser.TYPES, 0);
	};

	COLON() {
	    return this.getToken(BehaviorParser.COLON, 0);
	};

	ID = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.ID);
	    } else {
	        return this.getToken(BehaviorParser.ID, i);
	    }
	};


	COMMA = function(i) {
		if(i===undefined) {
			i = null;
		}
	    if(i===null) {
	        return this.getTokens(BehaviorParser.COMMA);
	    } else {
	        return this.getToken(BehaviorParser.COMMA, i);
	    }
	};


	accept(visitor) {
	    if ( visitor instanceof BehaviorVisitor ) {
	        return visitor.visitDecl_Ped(this);
	    } else {
	        return visitor.visitChildren(this);
	    }
	}


}




BehaviorParser.ProgramContext = ProgramContext; 
BehaviorParser.StatementContext = StatementContext; 
BehaviorParser.LocationContext = LocationContext; 
BehaviorParser.Location_attributeContext = Location_attributeContext; 
BehaviorParser.Loc_nameContext = Loc_nameContext; 
BehaviorParser.Loc_dimensionsContext = Loc_dimensionsContext; 
BehaviorParser.Loc_centerContext = Loc_centerContext; 
BehaviorParser.Loc_lengthsContext = Loc_lengthsContext; 
BehaviorParser.Loc_rotationContext = Loc_rotationContext; 
BehaviorParser.Value_numericContext = Value_numericContext; 
BehaviorParser.Value_numberContext = Value_numberContext; 
BehaviorParser.Value_floatContext = Value_floatContext; 
BehaviorParser.Value_rangeContext = Value_rangeContext; 
BehaviorParser.Value_randomContext = Value_randomContext; 
BehaviorParser.Random_numberContext = Random_numberContext; 
BehaviorParser.Random_floatContext = Random_floatContext; 
BehaviorParser.Float_rangeContext = Float_rangeContext; 
BehaviorParser.Number_rangeContext = Number_rangeContext; 
BehaviorParser.Value_coordContext = Value_coordContext; 
BehaviorParser.EventContext = EventContext; 
BehaviorParser.Event_attributeContext = Event_attributeContext; 
BehaviorParser.Event_nameContext = Event_nameContext; 
BehaviorParser.Event_startContext = Event_startContext; 
BehaviorParser.Event_endContext = Event_endContext; 
BehaviorParser.ConditionContext = ConditionContext; 
BehaviorParser.ConnectorContext = ConnectorContext; 
BehaviorParser.Or_ConnectorContext = Or_ConnectorContext; 
BehaviorParser.And_ConnectorContext = And_ConnectorContext; 
BehaviorParser.Sub_conditionContext = Sub_conditionContext; 
BehaviorParser.Condition_Time_Elapsed_From_EventContext = Condition_Time_Elapsed_From_EventContext; 
BehaviorParser.Condition_Event_OccurredContext = Condition_Event_OccurredContext; 
BehaviorParser.Condition_Event_OccurringContext = Condition_Event_OccurringContext; 
BehaviorParser.Condition_Event_StartingContext = Condition_Event_StartingContext; 
BehaviorParser.Condition_Event_EndingContext = Condition_Event_EndingContext; 
BehaviorParser.Condition_SpatialContext = Condition_SpatialContext; 
BehaviorParser.Condition_Enter_LocationContext = Condition_Enter_LocationContext; 
BehaviorParser.Condition_Exit_LocationContext = Condition_Exit_LocationContext; 
BehaviorParser.Condition_Inside_LocationContext = Condition_Inside_LocationContext; 
BehaviorParser.Condition_AttributeContext = Condition_AttributeContext; 
BehaviorParser.Condition_ExistsContext = Condition_ExistsContext; 
BehaviorParser.DurationContext = DurationContext; 
BehaviorParser.AttributeContext = AttributeContext; 
BehaviorParser.Attr_valueContext = Attr_valueContext; 
BehaviorParser.End_goalContext = End_goalContext; 
BehaviorParser.TargetContext = TargetContext; 
BehaviorParser.SelfContext = SelfContext; 
BehaviorParser.OtherContext = OtherContext; 
BehaviorParser.Nearest_typeContext = Nearest_typeContext; 
BehaviorParser.ModifierContext = ModifierContext; 
BehaviorParser.Location_modifierContext = Location_modifierContext; 
BehaviorParser.Id_listContext = Id_listContext; 
BehaviorParser.DistanceContext = DistanceContext; 
BehaviorParser.DirectionContext = DirectionContext; 
BehaviorParser.FrontContext = FrontContext; 
BehaviorParser.BehindContext = BehindContext; 
BehaviorParser.Ped_SelectorContext = Ped_SelectorContext; 
BehaviorParser.Selector_attributeContext = Selector_attributeContext; 
BehaviorParser.Selector_typeContext = Selector_typeContext; 
BehaviorParser.Selector_selectorContext = Selector_selectorContext; 
BehaviorParser.Selector_fromContext = Selector_fromContext; 
BehaviorParser.Selector_requiredContext = Selector_requiredContext; 
BehaviorParser.SelectorContext = SelectorContext; 
BehaviorParser.Selector_PercentContext = Selector_PercentContext; 
BehaviorParser.Selector_Exactly_N_RandomContext = Selector_Exactly_N_RandomContext; 
BehaviorParser.Selector_EveryoneContext = Selector_EveryoneContext; 
BehaviorParser.Selector_LocationContext = Selector_LocationContext; 
BehaviorParser.GroupContext = GroupContext; 
BehaviorParser.ActionContext = ActionContext; 
BehaviorParser.Action_attributeContext = Action_attributeContext; 
BehaviorParser.Action_stimulusContext = Action_stimulusContext; 
BehaviorParser.Action_responseContext = Action_responseContext; 
BehaviorParser.Action_durationContext = Action_durationContext; 
BehaviorParser.Action_targetContext = Action_targetContext; 
BehaviorParser.Sub_actionContext = Sub_actionContext; 
BehaviorParser.Action_atomContext = Action_atomContext; 
BehaviorParser.Set_atomContext = Set_atomContext; 
BehaviorParser.Scale_atomContext = Scale_atomContext; 
BehaviorParser.DeclarationContext = DeclarationContext; 
BehaviorParser.Decl_Ped_StateContext = Decl_Ped_StateContext; 
BehaviorParser.Decl_PedContext = Decl_PedContext; 
