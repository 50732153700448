export default
  `
Types:
  Luggage_Grabber
.

Location:
  Name: Aisle
  Dimensions:
    Center: {12, 1.7}
    Lengths: {30, 0.2}
    Rotation: 0
.

Selector:
  Type: Luggage_Grabber
  Select: 70%
.

Action (Luggage_Grabber):
  Condition: enter @Aisle
  Response: set velocity to {0}
  Duration: 10 seconds
.
`;
