
import { compare_selectors } from './comparisons/selectors';
import { compare_locations } from './comparisons/locations';
import { compare_events } from './comparisons/events';
import { compare_actions } from './comparisons/actions';
import { compare_states } from './comparisons/states';

import { range_check, value_sanity_check } from './checks/values';
import { selector_check } from './checks/selectors';


const check_behavior = (behavior, rules) => {

  let errors = [];

  base_check(behavior, errors);

  if (errors.length > 0) {
    return errors;
  }

  rule_check(behavior, rules, errors);

  return errors;
};

const base_check = (behavior, errors) => {
  range_check(behavior, errors);
  selector_check(behavior, errors);
  value_sanity_check(behavior, errors);
  // TODO: add more base checks
};

const rule_check = (behavior, rules, errors) => {
  compare_selectors(behavior, rules, errors);
  compare_locations(behavior, rules, errors);
  compare_events(behavior, rules, errors);
  compare_actions(behavior, rules, errors);
  compare_states(behavior, rules, errors);
};

export {
  check_behavior
}