
import { make_numeric, make_number } from './../values';

const make_select = (ctx) => {
  if (ctx.selector_Everyone()) {
    return {
      Type: 'Everyone',
      line: ctx.selector_Everyone().start.line,
      column: ctx.selector_Everyone().start.column
    };
  }

  if (ctx.selector_Location()) {
    return {
      Type: 'Location',
      Value: ctx.selector_Location().getText().split('@')[1],
      line: ctx.selector_Location().start.line,
      column: ctx.selector_Location().start.column
    };
  }

  if (ctx.selector_Exactly_N_Random()) {
    return {
      Type: 'Exactly',
      Value: make_numeric(ctx.selector_Exactly_N_Random().value_numeric()),
      line: ctx.selector_Exactly_N_Random().start.line,
      column: ctx.selector_Exactly_N_Random().start.column
    };
  }

  if (ctx.selector_Percent()) {
    return {
      Type: 'Percent',
      Value: make_numeric(ctx.selector_Percent().value_numeric()),
      line: ctx.selector_Percent().start.line,
      column: ctx.selector_Percent().start.column
    };
  }

  return null;
};

export {
  make_select
};