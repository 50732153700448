
import './styles/prism.css'
import './styles/exercise.css'
import Prism from 'prismjs/components/prism-core';

import Editor from 'react-simple-code-editor';

Prism.languages.bhvr = {
  'comment': /\/\/.*/i,
  'string': /(?:(@|!|#)[a-zA-Z_]*)/,
  'keyword': /\b(?:Name|Dimensions|End|Center|Lengths|Rotation|Type|Select|Condition|Start|Response|Duration)\b/i,
  'important': /\b(?:Types|Selector|Location|Action|Event|States)\b/i,
  'number': /\b(?:(\d+)|(\d+%))\b/i,
  'punctuation': /[{}[\];(),.:]/i,
};

export default function BhvrEditor(props) {
  return (
    <div className="grid-item">
      <div className="container__editor__area">
        <Editor
          className="container__editor"
          value={props.Text}
          onValueChange={props.onValueChange}
          highlight={code =>
            Prism.highlight(code, Prism.languages.bhvr)
              .split('\n')
              .map(
                line =>
                  `<span class="container_editor_line_number">${line}</span>`
              )
              .join('\n')
          }
          padding={"4%"}
        />
      </div>
    </div>
  );
};