
import BehaviorParser from '../grammar/generated/BehaviorParser';

const get_child = (ctx, name) => {
  if (ctx[name]) {
    return ctx[name]();
  }

  return ctx.children.find(child => {
    return BehaviorParser.ruleNames[child.ruleIndex] == name;
  }) || null;
};

const make_numeric = (ctx) => {
  let value = {
    Type: null,
    Value: null,
    line: null,
    column: null,
  };

  if (ctx.value_float() || ctx.value_number()) {
    value.Type = 'number';
    value.Value = parseFloat(ctx.getText());
    value.line = ctx.start.line;
    value.column = ctx.start.column;
  }

  if (ctx.value_random()) {
    value.Type = 'random';
    value.Value = { min: null, max: null };
    
    let min = 0;
    let max = 0;
    if (ctx.value_random().random_float()) {
      min = parseFloat(ctx.value_random().random_float().float_range().value_float()[0].FLOAT());
      max = parseFloat(ctx.value_random().random_float().float_range().value_float()[1].FLOAT());
      if (ctx.value_random().random_float().float_range().value_float()[0].children.length % 2 == 0) min = -min;
      if (ctx.value_random().random_float().float_range().value_float()[1].children.length % 2 == 0) max = -max;
    } else {
      min = parseFloat(ctx.value_random().random_number().number_range().value_number()[0].NUMBER());
      max = parseFloat(ctx.value_random().random_number().number_range().value_number()[1].NUMBER());
      if (ctx.value_random().random_number().number_range().value_number()[0].children.length % 2 == 0) min = -min;
      if (ctx.value_random().random_number().number_range().value_number()[1].children.length % 2 == 0) max = -max;
    }
    value.Value.min = min;
    value.Value.max = max;

    value.line = ctx.start.line;
    value.column = ctx.start.column;
  }

  if (ctx.value_range()) {
    value.Type = 'range';
    value.Value = { min: null, max: null };
    let min = 0;
    let max = 0;
    if (ctx.value_range().float_range()) {
      min = parseFloat(ctx.value_range().float_range().value_float()[0].FLOAT());
      max = parseFloat(ctx.value_range().float_range().value_float()[1].FLOAT());
      if (ctx.value_range().float_range().value_float()[0].children.length % 2 == 0) min = -min;
      if (ctx.value_range().float_range().value_float()[1].children.length % 2 == 0) max = -max;
    } else {
      min = parseFloat(ctx.value_range().number_range().value_number()[0].NUMBER());
      max = parseFloat(ctx.value_range().number_range().value_number()[1].NUMBER());
      if (ctx.value_range().number_range().value_number()[0].children.length % 2 == 0) min = -min;
      if (ctx.value_range().number_range().value_number()[1].children.length % 2 == 0) max = -max;
    }
    value.Value.min = min;
    value.Value.max = max;
    value.line = ctx.start.line;
    value.column = ctx.start.column;
  }

  return value;
};

const make_attribute_value = (ctx) => {
  if (ctx.value_numeric()) {
    return {
      Type: 'numeric',
      Value: make_numeric(ctx.value_numeric()),
      line: ctx.start.line,
      column: ctx.start.column
    };
  }

  if (ctx.value_coord()) {
    return make_coord(ctx.value_coord());
  }

  if (ctx.STATE_VAL()) {
    return {
      Type: 'state',
      Value: ctx.STATE_VAL().getText(),
      line: ctx.start.line,
      column: ctx.start.column
    };
  }

  if (ctx.LOC_NAME()) {
    return {
      Type: 'location',
      Value: ctx.LOC_NAME().getText().split('@')[1],
      line: ctx.start.line,
      column: ctx.start.column
    };
  }

  return null;
};

const make_number = (ctx) => {
  const value = parseFloat(ctx.value_number().NUMBER());
  if (ctx.children.length % 2 == 0) value = -value;
  return {
    Type: 'numeric',
    Value: {
      Type: 'number',
      Value: value,
      line: ctx.start.line,
      column: ctx.start.column
    }
  };
};

const make_coord = (ctx) => {
  const coords = ctx.value_numeric();

  return {
    Type: 'coord',
    X: make_numeric(coords[0]),
    Y: coords[1] ? make_numeric(coords[1]) : { Type: 'number', Value: 0 },
    Z: coords[2] ? make_numeric(coords[2]) : { Type: 'number', Value: 0 },
    line: ctx.start.line,
    column: ctx.start.column
  };
};

const make_dimensions = (ctx) => {
  let dimensions = {
    Center: null,
    Lengths: null,
    Rotation: null,
    line: ctx.start.line,
    column: ctx.start.column
  };

  dimensions.Center = make_coord(ctx.loc_center().value_coord());
  dimensions.Lengths = make_coord(ctx.loc_lengths().value_coord());
  dimensions.Rotation = make_numeric(ctx.loc_rotation().value_numeric());

  return dimensions;
};

export {
  make_numeric,
  make_number,
  make_attribute_value,
  make_dimensions,
  get_child
}