
import './styles/references.css';

export default function References() {
  return (
    <div className={"referenceList"}>
      <h2>Reference Links:</h2>
      <li>
        <a
          href={"https://vipra-uwf.github.io/vipra/d2/d49/md_Documentation_2ResearcherFiles_2behaviors_2VIPRA__Behaviors.html"}
          rel="noopener"
          target="_blank"
        >
          VIPRA Behaviors Documentation
        </a>
      </li>
      <li>
        <a href={"https://erau.qualtrics.com/jfe/form/SV_25XcMpmQ9ycX0a2"} rel="noopener" target="_blank">
          VIPRA DSL Qualtrics Survey
        </a>
        </li>
    </div>
  );
}